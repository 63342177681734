import React from 'react';

const Button = ({
	type = 'primary',
	label,
	onClick,
	iconLeft,
	iconRight,
	disabled = false,
	loading = false,
	className = '',
	trackLabel, // New prop for tracking
}) => {
	const baseStyles =
		'flex items-center tracking-tight justify-center h-[52px] rounded-lg font-light transition-all duration-300 ease-in-out focus:outline-none';

	const primaryStyles =
		'bg-[#0B6E4F] text-white disabled:text-gray-500 px-[24px]  hover:bg-[#0B6E4F9E] disabled:bg-gray-100';

	const secondaryStyles =
		'bg-transparent text-[#0B6E4F] border border-[#0B6E4F] px-[24px]  hover:bg-[#0B6E4F9E] disabled:text-gray-500 disabled:bg-gray-100';

	const tertiaryStyles =
		'bg-transparent text-[#0B6E4F] hover:text-[#0B6E4F9E] px-[8px] disabled:text-gray-500';

	const buttonStyles =
		type === 'primary'
			? primaryStyles
			: type === 'secondary'
			? secondaryStyles
			: tertiaryStyles;

	const handleClick = (event) => {
		if (disabled || loading) return;

		if (window.gtag) {
			window.gtag('event', 'button_click', {
				event_category: 'Button',
				event_label: trackLabel || label,
				value: 1,
			});
		}

		if (onClick) {
			onClick(event);
		}
	};

	return (
		<button
			onClick={handleClick}
			disabled={disabled || loading}
			className={`${baseStyles} ${buttonStyles} ${className}`}>
			{loading ? (
				<div className='spinner w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin'></div>
			) : (
				<>
					{/* Icon on the left */}
					{iconLeft && <span className='mr-2'>{iconLeft}</span>}
					{label}
					{/* Icon on the right */}
					{iconRight && <span className='ml-2'>{iconRight}</span>}
				</>
			)}
		</button>
	);
};

export default Button;
