import React from 'react';

const OverviewBox = ({
	name,
	amount,
	growthRate,
	isPositive,
	icon,
	currency,
}) => {
	return (
		<div className='w-full h-[25vh] bg-white p-5 rounded-[12px] flex flex-col justify-between border'>
			<h2 className='font-medium text-[16px] text-secondary'>{name}</h2>
			<div>
				<div className='flex items-center gap-3'>
					{currency && (
						<span className='font-medium text-[20px] text-secondary'>
							{currency}
						</span>
					)}
					<h1 className='text-primary font-bold text-[28px]'>
						{amount?.toFixed(0)}
					</h1>
					<div
						className='flex text-center items-center gap-1 pl-2 pr-4 py-1 rounded-[13px]'
						style={{
							backgroundColor: isPositive ? '#E0F7EC' : '#FDE7E9',
						}}>
						<img
							src={icon}
							alt='Growth icon'
							className='w-[20px] h-[15px]'
						/>
						<span
							className={`font-semibold text-[16px] ${
								isPositive ? 'text-[#28A745]' : 'text-[#DC3545]'
							}`}>
							{growthRate}%
						</span>
					</div>
				</div>
				<p className='text-secondary font-medium text-[16px]'>
					vs last month
				</p>
			</div>
		</div>
	);
};

export default OverviewBox;
