import React, { useCallback, useEffect, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import Pagination from "./pagination";
import Dropdown from "./dropdown";
import StatusChip from "./statusChips";

const renderCell = (key, value) => {
  if (key === 'rawStatus') {
    return <StatusChip status={value} />;
  }

  // Default rendering for other cells
  return value;
};

const TableComponent = ({
  headers,
  data,
  rowsPerPage,
  currentPage,
  onTableClick,
  onActionClick,
  renderCell
}) => {
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleActionClick = (row, e) => {
    e.stopPropagation();
    setOpenDropdownId(openDropdownId === row.id ? null : row.id);
  };

  const closeDropdown = () => {
    setOpenDropdownId(null);
  };

  return (
    <div className="overflow-x-auto h-full">
      <table className="w-full">
        <thead className="sticky top-0 z-10">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="p-[16px] bg-[#EFFFEF] text-left text-[16px] font-medium text-[#101B10]"
              >
                {header}
              </th>
            ))}
            <th className="p-[16px] bg-[#EFFFEF] w-10"></th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((row) => {
            const { id, ...rest } = row;

            return (
              <tr
                key={id}
                className="border-b hover:bg-gray-50"
                onClick={() => onTableClick(row)}
              >
               {Object.entries(rest).map(([key, value], cellIndex) => (
                  <td key={cellIndex} className="py-[24px] px-4 text-gray-600">
                    {/* Custom render logic for specific cells */}
                    {renderCell ? renderCell(key, value, row) : value}
                  </td>
                ))}
                {/* {Object.values(rest).map((cell, cellIndex) => (
                  <td key={cellIndex} className="py-[24px] px-4 text-gray-600">
                    {cell}
                  </td>
                ))} */}
                <td className="py-[24px] px-4 text-right relative">
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={(e) => handleActionClick(row, e)}
                  >
                    <FaEllipsisV />
                  </button>
                  {openDropdownId === id && (
                    <Dropdown
                      actions={onActionClick(row)}
                      onClose={closeDropdown}
                      position="right-0 mt-2"
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const CardComponent = ({
  data,
  rowsPerPage,
  currentPage,
  onCardClick,
  onActionClick,
}) => {
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleActionClick = (product, e) => {
    e.stopPropagation();
    setOpenDropdownId(openDropdownId === product.id ? null : product.id);
  };

  const closeDropdown = () => {
    setOpenDropdownId(null);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 overflow-y-auto">
      {currentData.map((product) => (
        <div
          key={product.id}
          className="relative rounded-xl overflow-hidden"
          onClick={() => onCardClick(product)}
        >
          {product.images && (
            <div className="relative">
              <img
                src={product.images[0]}
                alt={product.name}
                className="w-full h-48 object-cover rounded-xl"
              />

              <div className="absolute top-3 right-4">
                <div className="relative">
                  <button
                    className="text-white p-1 rounded-full bg-gray-800 bg-opacity-50"
                    onClick={(e) => handleActionClick(product, e)}
                  >
                    <FaEllipsisV />
                  </button>
                </div>

                {openDropdownId === product.id && (
                  <Dropdown
                    actions={onActionClick(product)}
                    onClose={closeDropdown}
                    position="right-0 mt-2"
                  />
                )}
              </div>
            </div>
          )}
          <div className="py-4">
            <h2 className="text-lg font-semibold mb-1">{product.name}</h2>
            <div className="flex justify-between items-center">
              <span className="text-[14px] font-light text-secondary">
                NGN {product.unitPrice.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const DataDisplayRenderer = ({
  tabs,
  activeTab,
  setActiveTab,
  onCardClick,
  onTableClick,
  onActionClick,
  emptyStateMessage,
  currentPage,
  setCurrentPage,
  filterComponent,
}) => {
  const activeTabData = tabs.find((tab) => tab.name === activeTab);
  const [viewMode, setViewMode] = useState(activeTabData?.view || "table");

  const getRowsPerPage = useCallback(
    () => (viewMode === "table" ? 5 : 15),
    [viewMode]
  );

  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPage());

  useEffect(() => {
    setRowsPerPage(getRowsPerPage());
  }, [getRowsPerPage, viewMode]);

  useEffect(() => {
    if (activeTabData) {
      setViewMode(activeTabData.view);
    }
  }, [activeTabData]);

  const currentHeaders = activeTabData ? activeTabData.headers : [];
  const currentData = activeTabData ? activeTabData.data : [];
  const totalPages = Math.ceil(currentData.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setCurrentPage(1);
    const newTabData = tabs.find((tab) => tab.name === tabName);
    setViewMode(newTabData.view);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-4">
        <div className="flex space-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`px-4 pb-[16px] ${
                activeTab === tab.name
                  ? "border-b-2 border-primary text-primary"
                  : "text-[#7B8783]"
              }`}
              onClick={() => handleTabChange(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="flex  items-center space-x-8 bg-[#F8F8F8] py-3 px-6 rounded-full">
          {/* Filtering */}
          {filterComponent && (
            <button className="flex justify-center items-center">
              {filterComponent}
            </button>
          )}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <div className="flex-grow overflow-hidden">
        {currentData.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-lg font-semibold text-gray-500">
              {emptyStateMessage || "No data available"}
            </div>
          </div>
        ) : viewMode === "table" ? (
          <TableComponent
            headers={currentHeaders}
            data={currentData}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onTableClick={onTableClick}
            onActionClick={onActionClick}
            renderCell={renderCell}
          />
        ) : (
          <CardComponent
            data={currentData}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onCardClick={onCardClick}
            onActionClick={onActionClick}
          />
        )}
      </div>
    </div>
  );
};

export default DataDisplayRenderer;
