import React, {useState} from 'react'
import Dropdown from './dropdown';
import filter from '../../../assets/images/filter.svg'

const FilterWithDropdown = ({ selectedOption, setSelectedOption, dropdownItems, title, onFilterChange }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to toggle dropdown
    const [dropdownPosition, setDropdownPosition] = useState({}); // State for positioning
   
  
    const handleSelect = (option) => {
        setSelectedOption(option);
        if (onFilterChange) {
          onFilterChange(option);
        }
        handleCloseDropdown(); 
      };
    // Function to handle dropdown open/close
    const handleToggleDropdown = (e) => {
      setDropdownPosition({
        top: e.target.offsetTop + e.target.offsetHeight, // Example position: below the button
        left: e.target.offsetLeft,
      });
      setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown open/close
    };
  
    // Close dropdown when an option is selected
    const handleCloseDropdown = () => {
      setIsDropdownOpen(false);
    };
  
    return (
      <div className="relative inline-block">
        {/* Button to toggle dropdown */}
        <div className="flex items-center gap-6" onClick={handleToggleDropdown}>
        <button className="flex justify-center items-center" >
        <img src={filter} alt='Filter icon' className='w-[24px] h-[24px]'/></button>
        <button
          className=""
          onClick={handleToggleDropdown}>
          {selectedOption || title} 
        </button>
  </div>
        {/* Render Dropdown if open */}
        {isDropdownOpen && (
          <Dropdown
          actions={dropdownItems.map(item => ({
                        label: item.name,
                        onClick: () => handleSelect(item.name), 
                    }))}
            onClose={handleCloseDropdown} // Close dropdown when clicking outside
            position={`top-[${dropdownPosition.top}px] left-[${dropdownPosition.left}px]`} // Dynamically position dropdown
            width="min-w-[150px]" // Adjust width of dropdown as needed
          />
        )}
      </div>
    );
  };

  export default FilterWithDropdown