import React, { useState } from 'react';
import Exit from '../../../../assets/images/exit.svg';
// import Dropdown from "../../assets/images/dropDown.svg";
import SizeModal from './sizemodal';
import ToggleButton from './toggleButton';
import InputField from '../input_field';

const Electronics = ({
	selectedElectronics,
	setSelectedElectronics,
	sizes,
	setSizes,
	onToggle,
	productData,
	handleProductDataChange,
	toggleStates,
}) => {
	const [dropDown5, setDropDown5] = useState(false);
	const [newSize, setNewSize] = useState('');
	const [newColor, setNewColor] = useState('');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);

	const [selectedSizes, setSelectedSizes] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [currentCategory, setCurrentCategory] = useState('');

	//   const handleOpenModal = (category) => {
	//     setSelectedCategory(category);
	//     setIsOpen(true);
	//   };

	//   const handleCloseModal = () => {
	//     setIsOpen(false);
	//   };

	//   const handleAddSize = () => {
	//     // Handle the addition of the size here
	//     // console.log(`Adding size ${newSize} for ${selectedCategory}`);
	//     // Close the modal after adding size
	//     setIsOpen(false);
	//   };

	const handleSizeClick = (size, category) => {
		if (size === 'Custom') {
			setCurrentCategory(category);
			setIsSizeModalOpen(true);
		} else {
			// setSelectedSizes(size);
			setSelectedSizes(
				(prevSizes) =>
					prevSizes.includes(size)
						? prevSizes.filter((s) => s !== size) // Remove size if it's already selected
						: [...prevSizes, size], // Add size if it's not selected
			);
			handleProductDataChange('sizes', size, true);
		}
	};

	const handleAddSize = (category) => {
		if (newSize) {
			const updatedSizes = {
				...sizes,
				[category]: sizes[category]
					? [...sizes[category], newSize]
					: [newSize],
			};
			setSizes(updatedSizes);
			setNewSize('');
			setIsSizeModalOpen(false);

			handleProductDataChange('sizes', newSize, true);
		}
	};

	const renderSizeModal = () => {
		let modalProps = {};

		switch (currentCategory) {
			case 'laptop':
				modalProps = {
					title: 'Add Laptop Size',
					label: 'Input laptop size',
					placeholder: '15 inches',
					buttonText: 'Add Laptop Size',
				};
				break;
			case 'phone':
				modalProps = {
					title: 'Add Phone Size',
					label: 'Input phone size',
					placeholder: '6 inches',
					buttonText: 'Add Phone Size',
				};
				break;
			case 'camera':
				modalProps = {
					title: 'Add Camera Type',
					label: 'Input camera type',
					placeholder: 'DSLR',
					buttonText: 'Add Camera Type',
				};
				break;
			case 'tv':
				modalProps = {
					title: 'Add TV Size',
					label: 'Input Size',
					placeholder: '80',
					buttonText: 'Add Size',
				};
				break;
			default:
				break;
		}

		return (
			<SizeModal
				isOpen={isSizeModalOpen}
				onClose={() => setIsSizeModalOpen(false)}
				newSize={newSize}
				setNewSize={setNewSize}
				handleAddSize={handleAddSize}
				{...modalProps} // Spread the props specific to the category
			/>
		);
	};

	const toggleDropDown5 = () => {
		setDropDown5(!dropDown5);
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
          Type
        </label>
        <div className="border-t border-b flex justify-between items-center w-full rounded-[4px] h-[48px] py-[8px] px-[12px] border-borderNeutral">
          <label className=" text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%] ">
            {selectedElectronics}
          </label>
          <button onClick={toggleDropDown5} type="button">
            <img src={Dropdown} alt="Drop down button" />
          </button>
        </div> */}
				<InputField
					label='Type'
					value={productData.attributes.type || 'laptop'}
					handleValue={(value) =>
						handleProductDataChange('type', value, true)
					}
					// placeholder="Johndoe@gmail.com"
					// name="email"
					type='select'
					options={[
						{ label: 'Laptop', value: 'laptop' },
						{ label: 'TV', value: 'TV' },
						{ label: 'Smart phones', value: 'smartphone' },
						{ label: 'Camera', value: 'camera' },
					]}
				/>
			</div>
			{/* {dropDown5 && (
        <div className="bg-white p-4 w-full rounded-[8px] flex flex-col items-center justify-center gap-4">
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4  flex text-center justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
            onClick={() => {
              setSelectedElectronics("Laptops");
              setDropDown5(false);
              
      handleProductDataChange('type', 'laptop', true);
            }}
          >
            Laptops
          </button>
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%] "
            onClick={() => {
              setSelectedElectronics("TV");
              setDropDown5(false);
              
      handleProductDataChange('type', 'TV', true);
            }}
          >
            TV
          </button>
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
            onClick={() => {
              setSelectedElectronics("Smartphones");
              setDropDown5(false);
              
      handleProductDataChange('type', 'smartphone', true);
            }}
          >
            Smartphones
          </button>
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
            onClick={() => {
              setSelectedElectronics("Cameras");
              setDropDown5(false);
              
      handleProductDataChange('type', 'camera', true);
            }}
          >
            Cameras
          </button>
        </div>
      )} */}
			<div>
				{productData.attributes.type === 'laptop' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
								Available Sizes (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.laptop?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'laptop')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-sm ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-secondary'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<div className='flex gap-3'>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Ram(GB)
								</label>
								<input
									type='text'
									placeholder='Ex. 4'
									value={productData.attributes.ram || ''}
									onChange={(e) =>
										handleProductDataChange(
											'ram',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Processor
								</label>
								<input
									type='text'
									placeholder='Ex. Intel'
									value={
										productData.attributes.processor || ''
									}
									onChange={(e) =>
										handleProductDataChange(
											'processor',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
						</div>
						<div className='flex flex-col gap-3'>
							<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
								Storage(GB)
							</label>
							<input
								type='text'
								placeholder='Ex. 512'
								value={productData.attributes.storage || ''}
								onChange={(e) =>
									handleProductDataChange(
										'storage',
										e.target.value,
										true,
									)
								}
								className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
							/>
						</div>
						<div className='flex flex-col gap-3'>
							<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
								Operating System
							</label>
							<input
								type='text'
								placeholder='Ex. Windows'
								value={productData.attributes.os || ''}
								onChange={(e) =>
									handleProductDataChange(
										'os',
										e.target.value,
										true,
									)
								}
								className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
							/>
						</div>
					</div>
				)}

				{productData.attributes.type === 'TV' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
								Available Sizes (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.tv?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'tv')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-sm ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-secondary'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<div className='flex gap-3'>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Model
								</label>
								<input
									type='text'
									placeholder='Ex. LG'
									value={productData.attributes.model || ''}
									onChange={(e) =>
										handleProductDataChange(
											'model',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
						</div>
					</div>
				)}
				{productData.attributes.type === 'smartphone' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
								Available Sizes (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.smartphone?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'phone')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-sm ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-secondary'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<div className='flex gap-3'>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Ram(GB)
								</label>
								<input
									type='text'
									placeholder='Ex. 4'
									value={productData.attributes.ram || ''}
									onChange={(e) =>
										handleProductDataChange(
											'ram',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Camera Resolution
								</label>
								<input
									type='text'
									placeholder='Ex. 32MP'
									value={
										productData.attributes.resolution || ''
									}
									onChange={(e) =>
										handleProductDataChange(
											'resolution',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
						</div>
						<div className='flex flex-col gap-3'>
							<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
								Storage(GB)
							</label>
							<input
								type='text'
								placeholder='Ex. 512'
								value={productData.attributes.storage || ''}
								onChange={(e) =>
									handleProductDataChange(
										'storage',
										e.target.value,
										true,
									)
								}
								className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
							/>
						</div>
						<div className='flex flex-col gap-3'>
							<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
								Operating System
							</label>
							<input
								type='text'
								placeholder='Ex. iOS'
								value={productData.attributes.os || ''}
								onChange={(e) =>
									handleProductDataChange(
										'os',
										e.target.value,
										true,
									)
								}
								className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
							/>
						</div>
					</div>
				)}

				{productData.attributes.type === 'camera' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
								Camera Type
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.camera?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'camera')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-sm ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-secondary'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<div className='flex gap-3'>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Megapixels(MP)
								</label>
								<input
									type='text'
									placeholder='Ex. 4'
									value={productData.attributes.MP || ''}
									onChange={(e) =>
										handleProductDataChange(
											'MP',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Video Resolution
								</label>
								<input
									type='text'
									placeholder='Ex. 32MP'
									value={
										productData.attributes.resolution || ''
									}
									onChange={(e) =>
										handleProductDataChange(
											'resolution',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
						</div>
					</div>
				)}

				{renderSizeModal()}
			</div>
		</div>
	);
};

export default Electronics;
