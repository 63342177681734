import React, { useState, useRef, useEffect } from 'react';
import { FaBell, FaPlus } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { Drawer } from 'antd';
import Button from './button';
import Dropdown from './dropdown';
import SettingsModal from './settingsModal';
import ProductModal from './productflow/productModal';
import { useAPI } from '../../../apis/api_context';
import { FaGear } from 'react-icons/fa6';
import NotificationModal from './notificationModal';

const Header = ({ pageTitle, merchantData, refreshUser }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);
	const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
	const [isProductModalOpen, setIsProductModalOpen] = useState(false);
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [isNotificationModalOpen, setIsNotificationModalOpen] =
		useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchResults, setSearchResults] = useState(null);
	const [loading, setLoading] = useState(false);

	const searchInputRef = useRef(null);
	const apiService = useAPI();

	useEffect(() => {
		if (searchValue.trim()) {
			const fetchSearchResults = async () => {
				setLoading(true);
				try {
					const results = await apiService.searchMerchantData(
						searchValue,
					);
					setSearchResults(results);
				} catch (error) {
					// console.error('Error fetching search results:', error);
					setSearchResults(null);
				} finally {
					setLoading(false);
				}
			};

			const delayDebounceFn = setTimeout(fetchSearchResults, 300);
			return () => clearTimeout(delayDebounceFn);
		} else {
			setSearchResults(null);
		}
	}, [searchValue, apiService]);

	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
	const toggleCreateDropdown = () =>
		setIsCreateDropdownOpen(!isCreateDropdownOpen);
	const toggleSettingsModal = () =>
		setIsSettingsModalOpen(!isSettingsModalOpen);
	const handleOpenSearchModal = () => {
		setIsSearchModalOpen(true);
		setTimeout(() => searchInputRef.current?.focus(), 100);
	};

	const handleCloseSearchModal = () => {
		setIsSearchModalOpen(false);
		setSearchValue('');
		setSearchResults(null);
	};

	const handleOpenNotificationsModal = async () => {
		setIsNotificationModalOpen(true);
	};

	const renderResults = () => {
		if (loading) return <div>Loading...</div>;
		if (!searchResults || Object.keys(searchResults).length === 0)
			return <div>No results found.</div>;

		return (
			<div>
				{Object.entries(searchResults).map(([key, results]) => {
					if (results.length > 0) {
						return (
							<div key={key}>
								<h3>{key.toUpperCase()}</h3>
								<ul>
									{results.map((item, index) => (
										<li key={index}>
											<pre>
												{JSON.stringify(item, null, 2)}
											</pre>
										</li>
									))}
								</ul>
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	};

	return (
		<header className='bg-white border-b'>
			<div className='hidden md:flex justify-between items-center p-4'>
				<h1 className='text-lg font-semibold'>{pageTitle}</h1>
				<div className='flex items-center gap-4'>
					<div className='relative w-full mt-1 flex items-center'>
						<span className='absolute left-3 flex items-center justify-center h-full'>
							<IoIosSearch />
						</span>
						<input
							type='text'
							onClick={handleOpenSearchModal}
							placeholder='Search…'
							className='pl-8 w-full h-[48px] rounded-md bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] text-primary border border-[#e3e3e3] focus:border-green-600'
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</div>

					<div className='relative'>
						<Button
							type='primary'
							label='Create'
							onClick={toggleCreateDropdown}
							iconLeft={<FaPlus />}
						/>
						{isCreateDropdownOpen && (
							<Dropdown
								actions={[
									{
										label: 'Create New Product',
										onClick: () =>
											setIsProductModalOpen(true),
									},
								]}
								onClose={toggleCreateDropdown}
								position='right-0 mt-2'
								width='w-[200px]'
							/>
						)}
					</div>

					<div className='relative'>
						<Button
							type='tertiary'
							label={<FaBell size={20} />}
							onClick={handleOpenNotificationsModal}
						/>
						{isNotificationModalOpen && (
							<NotificationModal
								position='right-0 top-14'
								onClose={() =>
									setIsNotificationModalOpen(false)
								}
							/>
						)}
					</div>
					<Button
						type='tertiary'
						label={<FaGear size={20} />}
						onClick={toggleSettingsModal}
					/>
				</div>
			</div>

			<Drawer
				anchor='left'
				open={isSidebarOpen}
				onClose={toggleSidebar}
			/>

			{isSearchModalOpen && (
				<div
					className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70'
					onClick={handleCloseSearchModal}>
					<div
						className='bg-white w-[35vw] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col rounded-[12px]'
						onClick={(e) => e.stopPropagation()}>
						<input
							type='text'
							ref={searchInputRef}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
							placeholder='Type to search...'
							className='p-4 border-b'
							autoFocus
						/>
						<div className='p-4'>{renderResults()}</div>
					</div>
				</div>
			)}

			{isSettingsModalOpen && (
				<SettingsModal
					closeModal={toggleSettingsModal}
					refreshUser={refreshUser}
				/>
			)}

			{isProductModalOpen && (
				<ProductModal
					closeProductModal={() => setIsProductModalOpen(false)}
					merchantData={merchantData}
					refreshUser={refreshUser}
				/>
			)}
		</header>
	);
};

export default Header;
