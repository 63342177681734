import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { FaCheck } from 'react-icons/fa6';
import Exit from '../../../../assets/images/exit.svg';
// import Dropdown from "../../assets/images/dropDown.svg";
import SizeModal from './sizemodal';
import ToggleButton from './toggleButton';
import InputField from '../input_field';

const Footwear = ({
	sizes,
	setSizes,
	colors,
	setColors,
	onToggle,
	productData,
	handleProductDataChange,
	toggleStates,
}) => {
	const [newSize, setNewSize] = useState('');
	const [newColor, setNewColor] = useState('');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [isColorModalOpen, setIsColorModalOpen] = useState(false);
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedSizes, setSelectedSizes] = useState([]);

	const handleColorClick = (color) => {
		setSelectedColor(color);
		handleProductDataChange('colors', newColor, true);
	};

	const handleSizeClick = (size) => {
		if (size === 'Custom') {
			setIsSizeModalOpen(true);
			// // console.log('laptop')
		} else {
			setSelectedSizes(
				(prevSizes) =>
					prevSizes.includes(size)
						? prevSizes.filter((s) => s !== size) // Remove size if it's already selected
						: [...prevSizes, size], // Add size if it's not selected
			);
		}
	};
	// const handleAddColor = () => {
	//   if (newColor) {
	//     setColors([...colors, newColor]);
	//     setIsColorModalOpen(false); // Close the modal
	//   }
	// };
	const handleAddColor = () => {
		if (newColor) {
			const updatedColors = [...colors, newColor];
			setColors(updatedColors);
			setIsColorModalOpen(false);
			// console.log(newColor)

			handleProductDataChange('colors', newColor, true);
		}
	};
	const handleAddSize = (category) => {
		if (newSize) {
			const updatedSizes = {
				...sizes,
				[category]: sizes[category]
					? [...sizes[category], newSize]
					: [newSize],
			};
			setSizes(updatedSizes);
			setNewSize('');
			setIsSizeModalOpen(false);

			handleProductDataChange('sizes', updatedSizes, true);
		}
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
          Brand
        </label>
        <input
          type="text"
          placeholder="Ex. Nike"
          value={productData.attributes.brand || ''} 
  onChange={(e) => handleProductDataChange('brand', e.target.value, true)}
          className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
        /> */}
				<InputField
					label='Brand'
					value={productData.attributes.brand || ''}
					handleValue={(value) =>
						handleProductDataChange('brand', value, true)
					}
					placeholder='Ex. Nike'
					// name="productName"
					type='text'
				/>
			</div>
			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
					Available Sizes (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{sizes.footwear?.map((size, index) => (
						<button
							type='button'
							key={index}
							onClick={() => handleSizeClick(size)}
							className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-sm ${
								selectedSizes.includes(size)
									? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
									: 'border-[1px] border-secondary bg-white text-secondary'
							}`}>
							{size}
						</button>
					))}
				</div>
			</div>
			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
					Available Colors (optional)
				</h3>
				<div className='flex gap-2'>
					{colors.map((color, index) => (
						<div
							key={index}
							className={`w-8 h-8 cursor-pointer border ${
								color === selectedColor
									? 'border-2 border-black'
									: 'border border-gray-300'
							}`}
							style={{ backgroundColor: color }}
							onClick={() => handleColorClick(color)}
						/>
					))}

					<button
						type='button'
						onClick={() => setIsColorModalOpen(true)}
						className='text-sm text-basegreen'>
						+add colors
					</button>
				</div>
			</div>

			{isSizeModalOpen && (
				<SizeModal
					isOpen={isSizeModalOpen}
					onClose={() => setIsSizeModalOpen(false)}
					newSize={newSize}
					setNewSize={setNewSize}
					handleAddSize={() => handleAddSize('footwear')}
					title='Add New Sizes'
					label='Input Size'
					placeholder='49'
					buttonText='Add Size'
				/>
			)}

			{/* Color Modal */}
			{isColorModalOpen && (
				<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
					<div className='bg-white p-6 rounded-lg w-[30vw] '>
						<div className='flex justify-between mb-8'>
							<h1 className=' text-[24px] leading-[28px] -tracking-[3%] text-primary'>
								Add New Color
							</h1>
							<button
								onClick={() => {
									setIsColorModalOpen(false);
								}}>
								<img
									src={Exit}
									alt='Exit button'
									className='w-[28px] h-[28px]'
								/>
							</button>
						</div>
						<div className='flex justify-center items-center w-48 h-48 rounded overflow-hidden mx-auto'>
							<HexColorPicker
								color={newColor}
								onChange={setNewColor}
								className='border'
							/>
							{/* <input type="color"/> */}
						</div>
						<div className='mt-4'>
							<h4 className=' text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%]'>
								Selection
							</h4>
							<div className='flex items-center justify-center gap-2 mt-4 mb-8'>
								<div
									className='w-8 h-8'
									style={{ backgroundColor: newColor }}
								/>
								<input
									type='text'
									value={newColor}
									readOnly
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
						</div>
						<button
							type='button'
							onClick={handleAddColor}
							className='bg-basegreen text-white px-4 py-2 rounded w-full'>
							Add Color
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Footwear;
