import React, { useEffect, useState, useRef } from 'react';
import { useAPI } from '../../../apis/api_context';
import {
	FaInfoCircle,
	FaCheckCircle,
	FaExclamationCircle,
	FaTimesCircle,
} from 'react-icons/fa';

const NotificationModal = ({ position, onClose }) => {
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const apiService = useAPI();
	const modalRef = useRef(null);

	useEffect(() => {
		const fetchNotifications = async () => {
			try {
				const fetchedNotifications =
					await apiService.getMerchantNotifications();
				setNotifications(fetchedNotifications);
			} catch (error) {
				setError('Failed to load notifications.');
			} finally {
				setLoading(false);
			}
		};

		fetchNotifications();
	}, [apiService]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	const renderIcon = (type) => {
		switch (type) {
			case 'transactions':
				return (
					<FaInfoCircle
						className='text-blue-500'
						size={21}
					/>
				);
			case 'security':
				return (
					<FaCheckCircle
						className='text-green-500'
						size={21}
					/>
				);
			case 'promotions':
				return (
					<FaExclamationCircle
						className='text-yellow-500'
						size={21}
					/>
				);
			case 'orders':
				return (
					<FaTimesCircle
						className='text-red-500'
						size={21}
					/>
				);
			case 'store':
				return (
					<FaInfoCircle
						className='text-purple-500'
						size={21}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div
			ref={modalRef}
			className={`absolute md:w-[45vw] lg:w-[30vw] ${position} max-h-[80vh] overflow-y-auto bg-white z-50 border border-borderNeutral rounded-[8px] flex flex-col font-firma shadow-xl`}>
			<div className='flex justify-between items-center sticky top-0 bg-white z-10 py-2 px-4 border-b border-gray-200'>
				<h2 className='text-xl font-medium text-primary'>
					Notifications
				</h2>
			</div>

			{/* Loading State with Skeleton */}
			{loading ? (
				<ul className='divide-y divide-gray-200 w-full'>
					{[...Array(3)].map((_, index) => (
						<li
							key={index}
							className='p-2 flex items-center space-x-2'>
							<div className='animate-pulse w-6 h-6 bg-gray-200 rounded-full'></div>
							<div className='flex-1'>
								<div className='h-4 bg-gray-200 rounded w-full mb-1'></div>
								<div className='h-4 bg-gray-200 rounded w-3/4'></div>
							</div>
						</li>
					))}
				</ul>
			) : error ? (
				<div className='text-center text-red-500'>{error}</div>
			) : notifications.length === 0 ? (
				<div className='text-center text-gray-500'>
					No notifications available.
				</div>
			) : (
				<ul className='divide-y divide-gray-200 w-full'>
					{notifications.map((notification) => (
						<li
							key={notification._id}
							className='py-4 px-4 flex items-start space-x-4'>
							{renderIcon(notification.type)}
							<div>
								<p className='font-medium text-primary'>
									{notification.title}
								</p>
								<p className='text-secondary text-sm'>
									{notification.body}
								</p>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default NotificationModal;
