import React, { useEffect } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

const ProtectedRoutes = () => {
	const { merchantId, accessToken } = useSelector((state) => state.auth);
	const userData = useSelector((state) => state.user);
	const location = useLocation();

	useEffect(() => {
		if (!merchantId || !accessToken || !userData.firstName) {
			toast.error('Something went wrong, please try again');
		}
	}, [merchantId, accessToken, userData.firstName]);

	// Check if the user is authenticated
	if (!merchantId || !accessToken || !userData.firstName) {
		return (
			<Navigate
				to='/signin'
				state={{ from: location }}
				replace
			/>
		);
	}

	return <Outlet />;
};

export default ProtectedRoutes;
