import React, {useState} from 'react'
import Exit from "../../../../assets/images/exit.svg";
import Dropdown from "../../../../assets/images/dropDown.svg";
import ToggleButton from './toggleButton';
import InputField from '../input_field';
const Homegoods = ({ onToggle, productData, handleProductDataChange, toggleStates }) => {

  const [dropDown3, setDropDown3] = useState(false);
  const [dropDown4, setDropDown4] = useState(false);
  const [selectedOption3, setSelectedOption3] = useState("Choose One");
  const [selectedOption4, setSelectedOption4] = useState("cm");
  const toggleDropDown3 = () => {
    setDropDown3(!dropDown3);
  };
  const toggleDropDown4 = () => {
    setDropDown4(!dropDown4);
  };
  return (
    <div className="flex flex-col gap-10">
                        <div className="flex flex-col gap-3">
                          {/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                            Room Type
                          </label>
                          <div className="border-t border-b flex justify-between items-center w-full rounded-[4px] h-[48px] py-[8px] px-[12px] border-borderNeutral">
                            <label className=" text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%] ">
                              {selectedOption3}
                            </label>
                            <button onClick={toggleDropDown3} type="button">
                              <img src={Dropdown} alt="Drop down button" />
                            </button>
                          </div> */}
                          <InputField label="Room Type"
        value={productData.attributes.roomtype || 'Living Room'}
        handleValue={(value) => handleProductDataChange("roomtype", value, true)}
        // placeholder="Johndoe@gmail.com"
        // name="email"
        type="select"
        options={[
          { label: "Living Room", value: "Living Room" },
          { label: "Dining Room", value: "Dining Room" },
          { label: "Bedroom", value: "Bedroom" },
          { label: "Kitchen", value: "Kitchen" },
          { label: "Study", value: "Study" },
        ]}
        />
                        </div>

                        {/* {dropDown3 && (
                          <div className="bg-white p-4 w-full rounded-[8px] flex flex-col items-center justify-center gap-4">
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4  flex text-center justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption3("Choose One");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'None', true);
                              }}
                            >
                              Choose One
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%] "
                              onClick={() => {
                                setSelectedOption3("Living Room");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'Living room', true);
                              }}
                            >
                              Living Room
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption3("Dining Room");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'Dining room', true);
                              }}
                            >
                              Dining Room
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption3("Bedroom");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'Bedroom', true);
                              }}
                            >
                              Bedroom
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption3("Kitchen");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'Kitchen', true);
                              }}
                            >
                              Kitchen
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption3("Bathroom");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'bathroom', true);
                              }}
                            >
                              Bathroom
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption3("Study");
                                setDropDown3(false);
                                handleProductDataChange('roomtype', 'study', true);
                              }}
                            >
                              Study
                            </button>
                          </div>
                        )} */}
                        <div className="flex flex-col gap-3">
                          <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                            Weight
                          </label>
                          <input
                            type="text"
                            placeholder="Ex. 50kg"
                            className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
                          />
                        </div>
                        <p>Dimensions</p>
                        <div className="flex flex-col gap-3">
                          {/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                            Measurements
                          </label>
                          <div className="border-t border-b flex justify-between items-center w-full rounded-[4px] h-[48px] py-[8px] px-[12px] border-borderNeutral">
                            <label className=" text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%] ">
                              {selectedOption4}
                            </label>
                            <button onClick={toggleDropDown4} type="button">
                              <img src={Dropdown} alt="Drop down button" />
                            </button>
                          </div> */}
                                  <InputField label="Measurement"
        value={productData.attributes.measurement || 'cm'}
        handleValue={(value) => handleProductDataChange("measurement", value, true)}
        // placeholder="Johndoe@gmail.com"
        // name="email"
        type="select"
        options={[
          { label: "cm", value: "cm" },
          { label: "inches", value: "inches" },
          { label: "feet", value: "feet" },
          { label: "yard", value: "yard" },
        ]}
        />
                        </div>
                        {/* {dropDown4 && (
                          <div className="bg-white p-4 w-full rounded-[8px] flex flex-col items-center justify-center gap-4">
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4  flex text-center justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption4("cm");
                                setDropDown4(false);
                                handleProductDataChange('measurement', 'cm', true);
                              }}
                            >
                              cm
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%] "
                              onClick={() => {
                                setSelectedOption4("inches");
                                setDropDown4(false);
                                handleProductDataChange('measurement', 'inches', true);
                              }}
                            >
                              inches
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption4("feet");
                                setDropDown4(false);
                                handleProductDataChange('measurement', 'feet', true);
                              }}
                            >
                              feet
                            </button>
                            <button
                              type="button"
                              className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
                              onClick={() => {
                                setSelectedOption4("yard");
                                setDropDown4(false);
                                handleProductDataChange('measurement', 'yard', true);
                              }}
                            >
                              yard
                            </button>
                          </div>
                        )} */}
                        <div className="flex gap-3">
                          <div className="flex flex-col gap-3">
                            <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary">
                              Length
                            </label>
                            <input
                              type="text"
                              placeholder="Ex. 50cm"
                              className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
                            />
                          </div>
                          <div className="flex flex-col gap-3">
                            <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                              Width
                            </label>
                            <input
                              type="text"
                              placeholder="Ex. 50cm"
                              className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
                            />
                          </div>
                          <div className="flex flex-col gap-3">
                            <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                              Height
                            </label>
                            <input
                              type="text"
                              placeholder="Ex. 50cm"
                              className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
                            />
                          </div>
                        </div>

 
                      </div>
  )
}

export default Homegoods