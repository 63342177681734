import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import EditProductModal from '../components/productflow/productModal';
import ShareProductModal from '../components/shareProductModal';
import { numberWithCommas } from '../../../utils/add_commas';
import Layout from '../../../Layout';
import { RiShare2Line } from 'react-icons/ri';
import { IoArrowBack } from 'react-icons/io5';
import { FaChevronRight } from 'react-icons/fa';

const ProductDetailsPage = () => {
	const { productId } = useParams();
	const navigate = useNavigate();

	const [selectedProduct, setSelectedProduct] = useState(null);
	const [category, setCategory] = useState(null);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
	const [isShareModalOpen, setShareModalOpen] = useState(false);

	// Get user data from Redux store
	const userData = useSelector((state) => state.user);

	useEffect(() => {
		
		const product = userData.store.categories
			.flatMap((category) => category.products)
			.find((item) => item._id === productId);
		if (product) {
			setSelectedProduct(product);

			
			const productCategory = userData.store.categories.find((category) =>
				category.products.some((item) => item._id === productId),
			);
			setCategory(productCategory?.name);
		}
	}, [productId, userData]);

	const handleBackToProducts = () => {
		navigate(-1);
	};

	// const openEditModal = () => {
	// 	setEditModalOpen(true);
	// };

	const closeEditModal = () => {
		setEditModalOpen(false);
	};

	const openShareModal = () => {
		setShareModalOpen(true);
	};

	const closeShareModal = () => {
		setShareModalOpen(false);
	};

	const otherImages = selectedProduct?.images?.slice(1);

	return (
		<Layout>
			<div className='flex flex-col gap-5 overflow-y-scroll no-scrollbar scroll-smooth'>
				<div className='flex justify-between items-center w-full mb-[24px]'>
					<div className='flex items-center gap-8'>
						<button onClick={handleBackToProducts}>
							<IoArrowBack size={21} />
						</button>

						<div className='flex items-center gap-2'>
							<h2 className='text-[16px] font-[500] leading-[18px] -tracking-[3%] text-secondary'>
								Products
							</h2>
							<button className='text-secondary'>
								<FaChevronRight size={12} />
							</button>
							<h2 className='text-[16px] font-[500] leading-[18px] -tracking-[3%] text-primary'>
								{selectedProduct?.name}
							</h2>
						</div>
					</div>
					<div className='flex items-center gap-4'>
						<button onClick={openShareModal}>
							<div className='flex text-secondary items-center space-x-3'>
								<RiShare2Line size={24} />
								<span>Share</span>
							</div>
						</button>
						{/* <div className=''>
							<button
								onClick={openEditModal}
								className='flex items-center gap-2'>
								<img
									src={edit}
									alt='Edit button'
									className='w-[24px] h-[24px]'
								/>
								<span className='text-basegreen font-[500] text-[16px] leading-[18px] -tracking-[3%]'>
									Edit
								</span>
							</button>
						</div> */}
					</div>
				</div>
				<div className='grid grid-cols-2 gap-8'>
					<div className='flex flex-col space-y-4 w-full'>
						<img
							src={selectedProduct?.images[0]}
							alt={`Product ${selectedProduct?.name}`}
							className='rounded-[12px] w-full h-auto object-cover'
						/>
						<div className='w-full flex space-x-4 items-center overflow-x-auto pb-2'>
							{otherImages?.map((image, index) => (
								<img
									key={index}
									src={image}
									alt={`Product ${index + 1}`}
									className='h-[109px] w-auto rounded-[8px] flex-shrink-0'
								/>
							))}
						</div>
					</div>

					<div className='flex flex-col items-start'>
						<div className='flex justify-center items-center rounded-[8px] py-[4px] px-[8px] bg-[#E6F7F7] w-auto h-[28px] text-[#43A047] text-[16px] font-[500] leading-[20px] -tracking-[3%] mb-[31px]'>
							{category}
						</div>
						<h1 className='text-[40px] font-[500] leading-[46px] -tracking-[3%] text-primary mb-[43px]'>
							{selectedProduct?.name}
						</h1>
						<div className='flex flex-col gap-2 mb-[40px]'>
							<h2 className='text-[14px] font-[500] leading-[16px] -tracking-[3%] text-secondary'>
								Product Price
							</h2>
							<p className='text-[18px] font-[500] leading-[21px] -tracking-[3%] text-primary'>
								NGN{' '}
								{numberWithCommas(selectedProduct?.unitPrice)}
							</p>
						</div>
						<div className='flex flex-col gap-2 mb-[40px]'>
							<h2 className='text-[14px] font-[500] leading-[16px] -tracking-[3%] text-secondary'>
								Product Description
							</h2>
							<p className='text-[18px] font-[500] leading-[21px] -tracking-[3%] text-primary'>
								{selectedProduct?.description}
							</p>
						</div>
						<div className='flex gap-10'>
							<div className='flex flex-col gap-2'>
								<h2 className='text-[14px] font-[500] leading-[16px] -tracking-[3%] text-secondary'>
									Available Quantity
								</h2>
								<p className='text-[18px] font-[500] leading-[21px] -tracking-[3%] text-primary'>
									{selectedProduct?.availableQuantity} Units
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isEditModalOpen && (
				<EditProductModal
					closeProductModal={closeEditModal}
					selectedProduct={selectedProduct}
					modalName={'Edit Product'}
					modalButton={'Save Changes'}
				/>
			)}
			{isShareModalOpen && (
				<ShareProductModal
					closeShareModal={closeShareModal}
					selectedProduct={selectedProduct}
				/>
			)}
		</Layout>
	);
};

export default ProductDetailsPage;
