import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Toaster } from 'sonner';
import { navItems } from './utils/data/navItems';
import SideNav from './newUI/merchant/components/sidenav';
import Header from './newUI/merchant/components/header';

// CSS for pulsating skeleton effect
// const skeletonStyles = {
// 	skeleton: {
// 		background:
// 			'linear-gradient(-90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%)',
// 		backgroundSize: '200% 100%',
// 		animation: 'pulse 1.5s ease-in-out infinite',
// 		borderRadius: '8px',
// 		marginBottom: '16px',
// 	},
// 	'@keyframes pulse': {
// 		'0%': { backgroundPosition: '200% 0' },
// 		'100%': { backgroundPosition: '-200% 0' },
// 	},
// };

const Layout = ({ children, title, description }) => {
	// const { merchantId, accessToken } = useSelector((state) => state.auth);
	const userData = useSelector((state) => state.user);
	const location = useLocation();

	const getRouteTitle = (path) => {
		const matchedNavItem = navItems.find(
			(item) => path === item.to || path.startsWith(item.to),
		);
		return matchedNavItem ? `Manage your ${matchedNavItem.title}` : null;
	};

	const defaultTitle = (() => {
		const routeTitle = getRouteTitle(location.pathname);

		if (location.pathname === '/' || location.pathname === '/overview') {
			return `Hey ${userData?.firstName || 'User'} 👋`;
		}

		return routeTitle || `Hey ${userData?.firstName || 'User'} 👋`;
	})();

	return (
		<div className='flex min-h-screen h-screen overflow-hidden'>
			<Toaster position='top-right' />
			<SideNav merchantData={userData} />
			<main className='flex-1 bg-[#ffffff] overflow-hidden h-screen flex flex-col'>
				<Helmet>
					<title>{title || defaultTitle} | Merchant Dashboard</title>
					<meta
						name='description'
						content={
							description ||
							`${
								title || defaultTitle
							} - Dashboard Overview and Management`
						}
					/>
				</Helmet>

				<Header
					merchantData={userData}
					pageTitle={title || defaultTitle}
				/>

				<div className='flex-1 p-[24px] overflow-auto'>{children}</div>
			</main>
		</div>
	);
};

export default Layout;
