import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import { FaCheck, FaPen } from 'react-icons/fa';
import { useAPI } from '../../../apis/api_context';
import InputField from '../components/input_field';
import Layout from '../../../Layout';
import Button from '../components/button';
import { useAuth } from '../../../apis/auth_context';
import { FaX } from 'react-icons/fa6';
import LinearProgress from '@mui/material/LinearProgress';

const ManageProfile = () => {
	const merchantData = useSelector((state) => state.user);
	const { refreshUserData } = useAuth();
	const apiservice = useAPI();

	const [formData, setFormData] = useState({
		firstName: merchantData?.firstName || '',
		lastName: merchantData?.lastName || '',
		email: merchantData?.email || '',
		phoneNumber: merchantData?.phoneNumber || '',
		storeName: merchantData?.store?.storeName || '',
		contactEmail: merchantData?.store?.contactEmail || '',
		storePhoneNumber: merchantData?.store?.storePhone || '',
		address: merchantData?.store?.location?.address || '',
		businessType: merchantData?.store?.businessType || 'Manufacturer',
		noPhysicalLocation:
			merchantData?.store?.location?.noPhysicalLocation || false,
		accountNumber: merchantData?.settlementAccount?.accountNumber || '',
		accountName: merchantData?.settlementAccount?.accountName || '',
		bankCode: merchantData?.settlementAccount?.bankCode || '',
		bankName: merchantData?.settlementAccount?.bankName || '',
	});

	const [bankList, setBankList] = useState([]);
	const [filteredBanks, setFilteredBanks] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const dropdownRef = useRef();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	const toggleEdit = () => {
		if (isEditing) {
			setFormData((prev) => ({
				...prev,
				accountNumber:
					merchantData?.settlementAccount?.accountNumber || '',
				accountName: merchantData?.settlementAccount?.accountName || '',
				bankCode: merchantData?.settlementAccount?.bankCode || '',
				bankName: merchantData?.settlementAccount?.bankName || '',
			}));
		}
		setIsEditing(!isEditing);
	};

	useEffect(() => {
		// Fetch bank list on component mount
		const fetchBankList = async () => {
			try {
				const banks = await apiservice.getBanksList();
				setBankList(banks);
			} catch (error) {
				// console.error('Error fetching bank list:', error);
			}
		};

		fetchBankList();
	}, [apiservice]);

	const handleInputChange = (name, value) => {
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSavePersonalField = async (field, value) => {
		handleInputChange(field, value);
		try {
			await apiservice.updatePersonalDetails({ [field]: value });
			refreshUserData();
			toast.success('Personal details updated successfully!');
		} catch (error) {
			// console.error(`Error updating ${field} details:`, error);
			toast.error(`Failed to update ${field} details.`);
		}
	};

	const handleSaveBusinessField = async (field, value) => {
		handleInputChange(field, value);

		try {
			await apiservice.updateStoreDetails({ [field]: value });
			refreshUserData();
			toast.success('Business details updated successfully!');
		} catch (error) {
			// console.error(`Error updating ${field} details:`, error);
			toast.error(`Failed to update ${field} details.`);
		}
	};

	const handleBankInput = (value) => {
		setFormData((prev) => ({ ...prev, bankName: value }));
		const filtered = bankList.filter((bank) =>
			bank.name.toLowerCase().includes(value.toLowerCase()),
		);

		setFilteredBanks(filtered);
		setShowDropdown(true);
	};

	const handleBankSelect = (bank) => {
		setFormData((prev) => ({
			...prev,
			bankName: bank.name,
			bankCode: bank.code,
		}));
		// console.log(bank.name);
		// console.log(bank.code);
		// console.log(formData);
		// console.log(formData.bankName);
		// console.log(formData.bankCode);

		setShowDropdown(false);
	};

	const validateAccountNumber = async (accountNumber) => {
		setLoading(true);
		try {
			const response = await apiservice.resolveAccountName({
				accountNumber,
				bankCode: formData.bankCode,
			});

			if (response.status === 200) {
				setFormData((prev) => ({
					...prev,
					accountName: response.data.account_name,
				}));
			} else {
				toast.error('Invalid account number');
			}
		} catch (error) {
			// console.error('Error validating account number:', error);
			toast.error('Failed to validate account number');
		} finally {
			setLoading(false);
		}
	};

	const handleSaveBankDetails = async () => {
		try {
			const { accountNumber, accountName, bankCode, bankName } = formData;

			if (!accountNumber || !accountName || !bankCode || !bankName) {
				toast.error('Please complete all banking details');
				return;
			}

			await apiservice.updateBankDetails({
				accountNumber,
				accountName,
				bankCode,
				bankName,
			});
			refreshUserData();
			toast.success('Banking details updated successfully!');
		} catch (error) {
			// console.error('Error saving bank details:', error);
			toast.error('Failed to update banking details.');
		}
	};
	const [isBusinessNameAvailable, setIsBusinessNameAvailable] =
		useState(null);

	const checkBusinessNameAvailability = useCallback(
		async (name) => {
			if (name.trim() === '') {
				return { isAvailable: null, message: '' };
			}

			if (/^[a-zA-Z\s']*$/.test(name)) {
				try {
					const response = await apiservice.checkBusinessName(name);
					return {
						isAvailable: !response.exists,
						message: response.exists
							? 'Business name is taken'
							: 'Business name is available',
					};
				} catch (error) {
					// console.error('Error checking business name:', error);
					return {
						isAvailable: null,
						message: 'Error checking business name availability.',
					};
				}
			} else {
				return {
					isAvailable: false,
					message:
						'Business name can only contain letters, spaces, and apostrophes.',
				};
			}
		},
		[apiservice],
	);

	return (
		<Layout>
			<div className='bg-white no-scrollbar scroll-smooth'>
				{/* Personal Details */}
				<h2 className='text-secondary font-[600] text-[24px] mb-5'>
					Personal Details
				</h2>
				<div className='space-y-2'>
					<ProfileField
						label='First Name'
						value={formData.firstName}
						description={
							'Couriers and Customers can put a name to your business'
						}
						onSave={(value) =>
							handleSavePersonalField('firstName', value)
						}
					/>
					<ProfileField
						label='Last Name'
						value={formData.lastName}
						onSave={(value) =>
							handleSavePersonalField('lastName', value)
						}
					/>
					<ProfileField
						label='Email'
						value={formData.email}
						onSave={(value) =>
							handleSavePersonalField('email', value)
						}
					/>
					<ProfileField
						label='Phone Number'
						value={formData.phoneNumber}
						onSave={(value) =>
							handleSavePersonalField('phoneNumber', value)
						}
					/>
				</div>

				{/* Business Details */}
				<h2 className='text-secondary font-[600] text-[24px] mb-5 mt-10'>
					Business Details
				</h2>
				<ProfileField
					label='Business/Store Name'
					description='This is the name displayed on your storefront'
					value={formData.storeName}
					onSave={(value) =>
						handleSaveBusinessField('storeName', value)
					}
					checkBusinessName={checkBusinessNameAvailability}
				/>

				<ProfileField
					label='Business Contact Email'
					description='Update your business contact email'
					value={formData.contactEmail}
					onSave={(value) =>
						handleSaveBusinessField('contactEmail', value)
					}
				/>
				<ProfileField
					label='Business Phone Number'
					description='Update your business phone number'
					value={formData.storePhoneNumber}
					onSave={(value) =>
						handleSaveBusinessField('storePhoneNumber', value)
					}
				/>
				<ProfileField
					label='Business Address'
					description='Update your business address'
					value={formData.address}
					onSave={(value) =>
						handleSaveBusinessField('address', value)
					}
				/>

				{/* Banking Details */}
				<h2 className='text-secondary font-[600] text-[24px] mb-5 mt-10'>
					Banking
				</h2>
				<div className='flex justify-between py-4 border-b'>
					<div className='flex-grow'>
						<p className='text-[16px] text-primary'>
							Settlement Accounts
						</p>
						<p className='text-[13px] text-secondary'>
							Manage your settlement account information.
						</p>
					</div>
					{isEditing ? (
						<div className='space-y-4'>
							<div className='relative'>
								<InputField
									value={formData.bankName}
									handleValue={handleBankInput}
									type='text'
									placeholder='Type to search your Bank'
									className='mb-2'
								/>
								{showDropdown && filteredBanks.length > 0 && (
									<ul className='absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto'>
										{filteredBanks.map((bank, index) => (
											<li
												key={index}
												className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
												onClick={() =>
													handleBankSelect(bank)
												}>
												{bank.name}
											</li>
										))}
									</ul>
								)}
							</div>

							<InputField
								value={formData.accountNumber}
								handleValue={(value) => {
									handleInputChange('accountNumber', value);
									if (value.length === 10) {
										validateAccountNumber(value);
									}
								}}
								type='text'
								className='mb-4'
								placeholder='Enter your account number'
							/>
							{loading && <LinearProgress />}
							{formData.accountName && (
								<p className='text-basegreen capitalize text-sm mb-4'>
									{formData.accountName}
								</p>
							)}
							<div className='flex space-x-4'>
								<Button
									label='Close'
									type='secondary'
									onClick={toggleEdit}
								/>
								<Button
									label='Save Banking Details'
									onClick={handleSaveBankDetails}
									disabled={
										!formData.accountNumber ||
										!formData.accountName ||
										!formData.bankCode ||
										!formData.bankName ||
										loading
									}
								/>
							</div>
						</div>
					) : (
						<div className=' items-center space-y-4'>
							<InputField
								value={
									formData.accountNumber || 'No Account Set'
								}
								disabled={true}
							/>

							<InputField
								value={formData.bankName || 'No Account Set'}
								disabled={true}
							/>
							<button
								onClick={toggleEdit}
								className='text-basegreen'>
								Edit
							</button>
						</div>
					)}
				</div>

				{/* Account Security */}
				<div className='mt-10 mb-16'>
					<h2 className='text-secondary font-[600] text-[24px] mb-5'>
						Account Security
					</h2>
					<div className='flex items-center justify-between'>
						<div>
							<p className='text-primary font-[500] text-[16px]'>
								Update your password
							</p>
							<p className='text-secondary text-[13px]'>
								Change your old password to a new one
							</p>
						</div>
						<button className='text-basegreen font-[500] text-[16px]'>
							Update Password
						</button>
					</div>
				</div>
			</div>
		</Layout>
	);
};

const ProfileField = ({
	label,
	description,
	value,
	onSave,
	checkBusinessName,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [editedValue, setEditedValue] = useState(value);
	const [isAvailable, setIsAvailable] = useState(null);
	const [availabilityMessage, setAvailabilityMessage] = useState('');

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleClose = () => {
		setIsEditing(false);
		setEditedValue(value);
		setIsAvailable(null);
		setAvailabilityMessage('');
	};

	const handleSave = async () => {
		if (label === 'Business/Store Name' && !isAvailable) {
			toast.error('Please choose an available business name.');
			return;
		}

		try {
			await onSave(editedValue);
			setIsEditing(false);
			setIsAvailable(null);
			setAvailabilityMessage('');
		} catch (error) {
			// console.error('Error saving data:', error);
			toast.error('Failed to save. Please try again.');
		}
	};

	const handleChange = async (newValue) => {
		setEditedValue(newValue);
		if (label === 'Business/Store Name' && checkBusinessName) {
			const { isAvailable, message } = await checkBusinessName(newValue);
			setIsAvailable(isAvailable);
			setAvailabilityMessage(message);
		}
	};

	return (
		<div className='flex items-start justify-between py-4 border-b'>
			<div className='flex-grow'>
				<p className='text-[16px] text-primary'>{label}</p>
				<p className='text-[13px] text-secondary'>{description}</p>
			</div>
			<div className='items-start flex flex-col'>
				<div className='flex items-center space-x-4'>
					<InputField
						value={isEditing ? editedValue : value}
						handleValue={handleChange}
						name={label.toLowerCase().replace(' ', '-')}
						disabled={!isEditing}
						className='w-64'
					/>
					{isEditing ? (
						<div className='flex space-x-4 items-center'>
							<button
								onClick={handleClose}
								className='bg-gray-200 text-gray-600 h-[40px] w-[40px] items-center flex place-content-center rounded-full hover:text-gray-700 transition-colors'>
								<FaX />
							</button>
							<Button
								label={'Save'}
								onClick={handleSave}
								disabled={
									label === 'Business/Store Name' &&
									!isAvailable
								}
							/>
						</div>
					) : (
						<button
							onClick={handleEdit}
							className='text-gray-500 hover:text-gray-700 transition-colors'>
							<FaPen />
						</button>
					)}
				</div>
				{availabilityMessage && (
					<div
						className={`text-sm mb-4 w-full flex place-content-start mt-2 items-center space-x-1 ${
							isAvailable ? 'text-green-600' : 'text-red-500'
						}`}>
						{isAvailable && <FaCheck className='inline' />}
						<p>{availabilityMessage}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ManageProfile;
