import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Dropdown from './dropdown';

const InputField = ({
	label,
	value,
	handleValue,
	placeholder,
	name,
	type,
	options,
	ref,
	className = '',
	inputClassName = '',
	selectClassName = '',
	textareaClassName = '',
	prefix = null,
	disabled = false,
}) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const togglePasswordVisibility = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	const onChange = (e) => {
		if (type === 'number') {
			const numValue =
				e.target.value === ''
					? ''
					: Math.max(0, parseInt(e.target.value, 10));
			handleValue(numValue);
		} else {
			handleValue(e.target.value);
		}
	};

	const handleOptionClick = (option) => {
		handleValue(option.value);
		setIsDropdownOpen(false);
	};

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<div
			className={`${className}`}
			ref={ref}>
			<label
				htmlFor={name}
				className='text-[13px] text-secondary'>
				{label}
			</label>
			{type === 'select' ? (
				<div className='relative w-full mt-1'>
					<button
					type='button'
						onClick={toggleDropdown}
						className={`w-full h-[48px] rounded-md bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] text-primary border border-[#e3e3e3] focus:border-green-600 ${selectClassName} ${
							disabled ? 'cursor-not-allowed opacity-50' : ''
						}`}
						disabled={disabled}>
						{value
							? options.find((option) => option.value === value)
									?.label
							: placeholder}
					</button>
					{isDropdownOpen && (
						<Dropdown
							actions={options.map((option) => ({
								label: option.label,
								onClick: () => handleOptionClick(option),
							}))}
							onClose={() => setIsDropdownOpen(false)}
							position='top-12'
							width='w-full'
						/>
					)}
				</div>
			) : type === 'textarea' ? (
				<textarea
					id={name}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={(e) => handleValue(e.target.value)}
					className={`w-full h-[72px] rounded-md bg-secondary/5 py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] text-primary mt-1 border border-[#e3e3e3] focus:border-green-600 placeholder-gray-400 ${textareaClassName}`}
					disabled={disabled}
				/>
			) : (
				<div className='relative w-full mt-1 flex items-center'>
					{prefix && (
						<span className='absolute left-3 flex items-center justify-center h-full'>
							{prefix}
						</span>
					)}
					<input
						id={name}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						type={
							type === 'password'
								? isPasswordVisible
									? 'text'
									: 'password'
								: type
						}
						name={name}
						className={`w-full h-[48px] rounded-md bg-bgNeutral py-[14px] ${
							prefix ? 'pl-14' : 'px-[12px]'
						} text-[15px] font-[400] leading-[20px] text-primary border border-[#e3e3e3] focus:border-green-600 placeholder-gray-400 ${inputClassName}`}
						{...(type === 'number' ? { min: '0', step: '1' } : {})}
						disabled={disabled}
					/>
					{type === 'password' && (
						<button
							type='button'
							onClick={togglePasswordVisibility}
							className='absolute right-3 top-1/2 transform -translate-y-1/2'>
							{isPasswordVisible ? (
								<FaEyeSlash size={20} />
							) : (
								<FaEye size={20} />
							)}
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default InputField;
