import React, { useEffect, useState } from 'react';
import map from '../../../assets/images/map.svg';
import link from '../../../assets/images/link.svg';
import addProductIcon from '../../../assets/images/shopping-bag.svg';
import bankIcon from '../../../assets/images/credit-card.svg';
import publishIcon from '../../../assets/images/sitemap.svg';
import handleCopyToClipboard from '../../../utils/copyToClipboard';
import Card from '../components/card';
import { useNavigate } from 'react-router';
import Layout from '../../../Layout';
import { useSelector } from 'react-redux';
import { StoreInfo } from './overview';
import { MapIcon, LinkIcon } from 'lucide-react';

const Onboarding = ({ merchantData, handleOpenProductModal }) => {
	const { analytics, store, subscription } = useSelector(
		(state) => state.user,
	);
	const [storeLocationComplete, setStoreLocationComplete] = useState(false);
	const [storeLinkComplete, setStoreLinkComplete] = useState(false);
	const navigate = useNavigate();
	// console.log(store);

	useEffect(() => {
		store?.location?.address
			? setStoreLocationComplete(true)
			: setStoreLocationComplete(false);
		store?.storeLink
			? setStoreLinkComplete(true)
			: setStoreLinkComplete(false);
	}, [store]);

	return (
		<Layout>
			{/* <div className="bg-white pt-[110px] pb-3 px-8 overflow-y-scroll no-scrollbar font-firma scroll-smooth"> */}
			<div className='flex gap-6 flex-wrap'>
				{/* Store Address */}
				{/* <div className="flex flex-col gap-3 w-[300px]">
          <h3 className="text-[14px] leading-[16px] text-secondary -tracking-[3%] font-[500] mx-1">
            Your store address
          </h3>
          {storeLocationComplete ? (
            <button
            className="flex items-center gap-2 w-auto border border-borderNeutral p-3 rounded-[30px]"
              onClick={() =>
                handleCopyToClipboard(
                  'Store Address Copied',
                  store?.location?.address
                )
              }
              >
              <img src={map} alt="Location icon" className="w-[16px] h-[16px]" />
              <span className="text-primary font-[500] text-[16px] leading-[18px] -tracking-[3%]">
                {store?.location?.address}
              </span>
            </button>
          ) : (
            <input
            type="text"
            placeholder="Enter store address"
            className="border border-gray-300 rounded-[30px] p-3 w-full"
            />
          )}
        </div> */}
				<div className='flex gap-4 flex-wrap'>
					{store?.location?.address && (
						<StoreInfo
							icon={<MapIcon size={16} />}
							text={store.location.address}
							label='Your store address'
							onCopy={() =>
								handleCopyToClipboard(
									'Store Address Copied',
									store.location.address,
								)
							}
						/>
					)}
					{store?.storeLink && (
						<StoreInfo
							icon={<LinkIcon size={16} />}
							text={store.storeLink}
							label='Your store link'
							onCopy={() =>
								handleCopyToClipboard(
									'Store Link Copied',
									store.storeLink,
								)
							}
						/>
					)}
				</div>

				{/* Store Link */}
				{/* <div className="flex flex-col gap-3 w-[300px]">
          <h3 className="text-[14px] leading-[16px] text-secondary -tracking-[3%] font-[500] mx-1">
            Your store link
          </h3>
          {storeLinkComplete ? (
            <button
            className="flex items-center gap-2 w-auto border border-borderNeutral p-3 rounded-[30px]"
            onClick={() =>
                handleCopyToClipboard(
                  'Store Link Copied',
                  `${store?.storeLink}/${merchantData?.store?._id}`
                )
              }
              >
              <img src={link} alt="Link to business icon" className="w-[16px] h-[16px]" />
              <span className="text-primary font-[500] text-[16px] leading-[18px] -tracking-[3%]">
                {`${store?.storeLink}`}
              </span>
            </button>
          ) : (
            <input
            type="text"
            placeholder="Generate link"
            className="border border-gray-300 rounded-[30px] p-3 w-full"
            />
          )}
        </div> */}
			</div>
			<div className='my-12 flex flex-col gap-2'>
				<h1 className='text-primary text-[24px] text-center'>
					Hey there {merchantData?.firstName}, Welcome to Ridefraser
				</h1>
				<p className='text-secondary text-center'>
					Let’s complete your profile and get you started with
					seamless logistics super fast
				</p>
			</div>
			<div className='flex justify-center gap-6 mt-6'>
				<Card
					icon={addProductIcon}
					title='Add your first product'
					description='Showcase your product offerings with clear descriptions'
					buttonText='Add Product'
					onClick={handleOpenProductModal}
				/>
				{!store?.location?.address && (
					<Card
						icon={map}
						title='Set your store location'
						description='Let customers know when to pick up their orders or visit you in person.'
						buttonText='Set Location'
						onClick={() => navigate('/settingup')}
					/>
				)}
				<Card
					icon={bankIcon}
					title='Connect your bank'
					description='Get paid smoothly for all your sales - simply add your bank details'
					buttonText='Connect Account'
					onClick={() => navigate('/settingup')}
				/>
				{/* <Card
          icon={publishIcon}
          title="Publish first product page"
          description="Publish your store and let the world know you're open for business"
          buttonText="Get Link"
          onClick={() => // console.log("Get Link Clicked")}
          /> */}
			</div>
			{/* </div> */}
		</Layout>
	);
};

export default Onboarding;
