import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Avatar from './avatar';
import DropDown from '../../../assets/images/dropDown.svg';
import { useAuth } from '../../../apis/auth_context';
import { navItems } from '../../../utils/data/navItems';
import { paths } from '../../../routes';

const SideNav = ({ merchantData }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { logoutUser } = useAuth();
	const dropdownRef = useRef(null);
	const navigate = useNavigate();

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const menuItems = [
		{
			label: 'Manage Profile',
			action: () => {
				navigate(paths.PROFILE);
				setIsOpen(false);
			},
		},
		// {
		// 	label: 'Help',
		// 	action: () => {
		// 		setIsOpen(false);
		// 	},
		// },
		// {
		// 	label: 'Send Feedback',
		// 	action: () => {
		// 		setIsOpen(false);
		// 	},
		// },
		{
			label: 'Logout',
			action: () => {
				logoutUser();
				setIsOpen(false);
			},
		},
	];

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	if (!merchantData) {
		return <div>Loading...</div>;
	}

	return (
		<div className='w-64 h-screen bg-white p-4 shadow-lg border-r'>
			{/* Navigation Items */}
			<div
				className='flex items-center gap-3 mb-12 cursor-pointer'
				onClick={toggleDropdown}>
				<div className='w-[40px] h-[40px]'>
					<Avatar
						firstName={merchantData?.store?.storeName || ''}
						imageSrc={merchantData?.store?.settings?.logo}
					/>
				</div>

				<div className='flex-col space-y-1 w-2/3 overflow-clip'>
					<h1 className='text-[16px] truncate font-[500] leading-[18px] -tracking-[3%] text-primary'>
						{merchantData?.store?.storeName || 'Store Name'}
					</h1>
					{/* <p className='text-[14px] truncate font-[400] leading-[16px] -tracking-[3%] text-secondary'>
						{merchantData?.store?.contactEmail || 'Email'}
					</p> */}
				</div>
				<button>
					<img
						src={DropDown}
						alt='Drop down icon'
						className='w-[16px] h-[16px] cursor-pointer'
					/>
				</button>
			</div>

			{navItems.map((navItem, index) => (
				<div
					key={index}
					className='mb-[24px]'>
					{navItem.items ? (
						<>
							<p className='text-xs text-[#A4B1B1] uppercase mb-3'>
								{navItem.title}
							</p>
							{navItem.items.map((item, itemIndex) => (
								<NavItem
									key={itemIndex}
									label={item.title}
									to={item.to}
									icon={item.activeIcon}
									outlinedIcon={item.inactiveIcon}
								/>
							))}
						</>
					) : (
						<NavItem
							label={navItem.title}
							to={navItem.to}
							activeIcon={navItem.activeIcon}
							inactiveIcon={navItem.inactiveIcon}
							exact={navItem.exact}
						/>
					)}
				</div>
			))}

			{/* Open Profile Dropdown */}
			{isOpen && (
				<div
					ref={dropdownRef}
					className='bg-white w-[240px]  fixed top-5 left-[30px] z-50 border border-borderNeutral rounded-[8px] flex flex-col items-start justify-center gap-7 px-4 py-3 font-firma shadow-lg'>
					<div className='flex flex-col '>
						<div className='w-[40px] h-[40px]'>
							<Avatar
								firstName={merchantData?.store?.storeName || ''}
								imageSrc={merchantData?.store?.settings?.logo}
							/>
						</div>
						<h1 className='mt-4 text-[16px] font-[500] leading-[18px] -tracking-[3%] text-primary'>
							{merchantData?.store?.storeName || 'Store Name'}
						</h1>
						<p className='text-[14px] mt-1 font-[400] leading-[16px] -tracking-[3%] text-secondary'>
							{merchantData?.store?.contactEmail || 'Email'}
						</p>
					</div>
					<div className='flex flex-col items-center gap-4'>
						{menuItems.map((item, index) => (
							<button
								key={index}
								className='w-[205px] h-[50px] bg-bgNeutral rounded-[4px] p-3 text-[500] text-primary text-[16px] leading-[18px] -tracking-[3%] text-left'
								onClick={() => item.action()}>
								{item.label}
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

const NavItem = ({ label, to, activeIcon, inactiveIcon, exact }) => {
	return (
		<NavLink
			onClick={() => {
				localStorage.removeItem('activeTab');
				localStorage.removeItem('currentPage');
			}}
			to={to}
			exact={exact ? 'true' : undefined}
			className={({ isActive }) =>
				`flex items-center space-x-4 px-[16px] py-[12px] rounded-xl cursor-pointer transition-colors ${
					isActive
						? 'bg-[#0B6E4F] text-[#FFFFFF]'
						: 'text-[#7B8783] bg-transparent font-light'
				} block`
			}>
			{({ isActive }) => (
				<div className='flex items-center space-x-2'>
					{isActive ? activeIcon : inactiveIcon}
					<span className='text-center'>{label}</span>
				</div>
			)}
		</NavLink>
	);
};

export default SideNav;
