import React, { useState, useEffect, useCallback } from 'react';
import Exit from '../../../../assets/images/exit.svg';
import { FaRegTrashAlt } from 'react-icons/fa';
import Clothing from './clothing';
import Footwear from './footwear';
import Electronics from './electronics';
import Homegoods from './homegoods';
import ToggleButton from './toggleButton';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'sonner';
import InputField from '../../components/input_field';
import { useAPI } from '../../../../apis/api_context';
import { useAuth } from '../../../../apis/auth_context';
import Button from '../button';

const ProductModal = ({
	closeProductModal,
	isEditing,
	modalName,
	modalButton,
}) => {
	const { refreshUserData } = useAuth();
	console.log(modalName);
	const success = async () => {
		toast.success('Product created successfully!', {
			autoClose: 3000,
		});
		await refreshUserData();
	};
	const apiservice = useAPI();
	const [store, setStore] = useState([]);

	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const [images, setImages] = useState([]);
	const [imagePreviews, setImagePreviews] = useState([]);
	const [selectedElectronics, setSelectedElectronics] = useState('Laptops');
	const [sizes, setSizes] = useState({
		cloth: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'Custom'],
		footwear: [
			'34',
			'35',
			'36',
			'37',
			'38',
			'39',
			'40',
			'41',
			'42',
			'43',
			'44',
			'45',
			'46',
			'Custom',
		],
		laptop: ['5', '6', '6.5', '13', '14', '15', '16', 'Custom'],
		tv: ['10', '20', '30', '40', '50', '60', '70', 'Custom'],
		smartphone: ['5', '6', '6.5', '7', '8', '9', '12', 'Custom'],
		camera: ['DSLR', 'Mirrorless', 'Compact', 'Action', 'Custom'],
	});

	const [colors, setColors] = useState([]);
	const [productData, setProductData] = useState({
		category: '',
		name: '',
		price: '',
		description: '',
		stock: '',
		slug: '',
		productType: 'No special attributes',
		attributes: {},
		images: [],
	});

	const handleProductDataChange = (field, value, isAttribute = false) => {
		setProductData((prevData) => {
			if (isAttribute) {
				return {
					...prevData,
					attributes: {
						...prevData.attributes,
						[field]: value,
					},
				};
			} else {
				return {
					...prevData,
					[field]: value,
				};
			}
		});
	};

	const handleSubmit = async () => {
		setLoading(true);

		try {
			const slug = productData.name
				.toLowerCase()
				.replace(/[^a-z0-9]+/g, '-')
				.replace(/(^-|-$)/g, '');
			const formData = new FormData();
			formData.append('category', productData.category);
			formData.append('name', productData.name);
			formData.append('description', productData.description);
			formData.append('price', productData.price);
			formData.append('stock', productData.stock);
			formData.append('slug', slug);
			formData.append('productType', productData.productType);

			formData.append(
				'attributes',
				JSON.stringify(productData.attributes),
			);

			productData.images.forEach((image) => {
				formData.append('images', image);
			});

			let response;
			if (isEditing) {
				// Handle product editing
				response = await apiservice.editProduct(formData);
				// console.log(response.data);
			} else {
				// Handle product creation
				response = await apiservice.createProduct(formData);
			}

			closeProductModal();
			success();
			await refreshUserData();
			setProductData({
				category: '',
				name: '',
				price: 0,
				description: '',
				stock: 0,
				slug: '',
				productType: '',
				attributes: {},
				images: [],
			});
		} catch (error) {
			// console.error('Error creating product:', error);
			toast.error('Failed to update store appearance. Please try again.');
		} finally {
			setLoading(false);
		}
	};

	const [toggleStates, setToggleStates] = useState({
		production: false,
		shipping: false,
	});

	const handleToggleChange = (type, state) => {
		setToggleStates((prevStates) => ({
			...prevStates,
			[type]: state,
		}));
	};

	const handleImageUpload = (event) => {
		const files = Array.from(event.target.files); // Convert FileList to an array of files
		const updatedImages = [...images, ...files];
		const updatedImagePreviews = [
			...imagePreviews,
			...files.map((file) => URL.createObjectURL(file)),
		];

		// Limit to 5 images
		if (updatedImages.length > 5) {
			updatedImages.splice(5);
			updatedImagePreviews.splice(5);
		}

		setImages(updatedImages);
		setImagePreviews(updatedImagePreviews);
		handleProductDataChange('images', updatedImages); // Pass the actual files for FormData
	};

	const removeImage = (index) => {
		const updatedImages = images.filter((_, i) => i !== index);
		const updatedImagePreviews = imagePreviews.filter(
			(_, i) => i !== index,
		);

		setImages(updatedImages);
		setImagePreviews(updatedImagePreviews);

		// Update product data when an image is removed
		handleProductDataChange('images', updatedImages);
	};

	if (activeTab === '1') {
		return (
			<div
				className='fixed inset-0 z-50 flex items-start justify-end bg-black bg-opacity-70 font-firma'
				onClick={closeProductModal}>
				<div
					className='bg-white shadow-lg p-6 w-[420px] h-full overflow-y-scroll no-scrollbar scroll-smooth flex flex-col gap-8'
					onClick={(e) => e.stopPropagation()}>
					<div className='flex justify-between'>
						<h1 className='font-[600] text-[24px] leading-[28px] -tracking-[3%] text-primary'>
							{/* {modalName} */}
							Create New Product
						</h1>
						<button onClick={closeProductModal}>
							<img
								src={Exit}
								alt='Exit button'
								className='w-[28px] h-[28px]'
							/>
						</button>
					</div>
					<div className='flex mb-2 items-center'>
						<button
							className={`p-2 w-10 h-10 text-center rounded-full flex items-center justify-center mx-2 ${
								activeTab === '1'
									? 'bg-basegreen text-grey'
									: 'bg-gray-200 text-primary'
							}`}
							onClick={() => setActiveTab('1')}>
							1
						</button>
						<div className='h-1 mx-2 w-8 bg-gray-200'></div>
						<button
							className={`p-2 w-10 h-10 text-center rounded-full flex items-center justify-center mx-2 ${
								activeTab === '2'
									? 'bg-basegreen text-grey'
									: 'bg-gray-200 text-primary'
							}`}
							onClick={() => setActiveTab('2')}>
							2
						</button>
					</div>
					<h2 className='font-[500] text-[16px] leading-[18px] -tracking-[3%] text-primary'>
						Product Basics
					</h2>
					<form className='flex flex-col gap-3'>
						<div className='flex flex-col gap-3'>
							{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                Product Name
              </label>
              <input
                type="text"
                value={productData.name}
                onChange={(e) =>
                  handleProductDataChange("name", e.target.value)
                }
                placeholder="Product Name"
                className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-primary "
              /> */}
							<InputField
								label='Product Name'
								value={productData.name}
								handleValue={(value) =>
									handleProductDataChange('name', value)
								}
								placeholder='Enter product name'
								name='productName'
								type='text'
							/>
						</div>
						<div className='flex flex-col gap-3'>
							{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                Product Description
              </label>
              <textarea
                type="text"
                value={productData.description}
                onChange={(e) =>
                  handleProductDataChange("description", e.target.value)
                }
                placeholder="Description"
                className="w-full h-[72px] rounded-[8px] bg-secondary/5 py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
              ></textarea> */}
							<InputField
								label='Product Description'
								value={productData.description}
								handleValue={(value) =>
									handleProductDataChange(
										'description',
										value,
									)
								}
								placeholder='Enter product description'
								name='productDescription'
								type='textarea'
							/>
						</div>
						<div className='flex flex-col gap-3'>
							{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                Product Category
              </label>
              <input
                type="text"
                value={productData.category}
                onChange={(e) =>
                  handleProductDataChange("category", e.target.value)
                }
                placeholder="Category"
                className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
              /> */}
							<InputField
								label='Product Category'
								value={productData.category}
								handleValue={(value) =>
									handleProductDataChange('category', value)
								}
								placeholder='Category'
								name='productCategory'
								type='text'
							/>
						</div>
						<div className='flex flex-col gap-3'>
							{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                Unit Price
              </label>
              <input
                type="number"
                value={productData.price}
                onChange={(e) =>
                  handleProductDataChange("price", e.target.value)
                }
                placeholder="1000"
                className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] "
              /> */}
							<InputField
								label='Unit Price'
								value={productData.price}
								handleValue={(value) =>
									handleProductDataChange('price', value)
								}
								placeholder='1000'
								// name="productName"
								type='number'
							/>
						</div>
						<div className='flex flex-col gap-3'>
							<InputField
								label='Available Quantity (SKU)'
								value={productData.stock}
								handleValue={(value) =>
									handleProductDataChange('stock', value)
								}
								placeholder='0'
								type='number'
							/>
						</div>
						<div className=''>
							<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
								Product Images (5 Max)
							</label>
							<div className='flex space-x-2'>
								{imagePreviews.map((image, index) => (
									<div
										key={index}
										className='relative group w-16 h-16 rounded overflow-hidden mt-4'>
										<img
											src={image}
											alt={`product-${index}`}
											className='object-cover w-full h-full'
										/>
										<div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity'></div>
										<button
											onClick={() => removeImage(index)}
											className='absolute top-5 right-5 bg-red-600 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity'>
											<FaRegTrashAlt size={20} />
										</button>
									</div>
								))}
								{imagePreviews.length < 5 && (
									<label className='mt-4 flex justify-center items-center w-16 h-16 border border-dashed border-gray-300 rounded cursor-pointer'>
										<span className='text-gray-400 text-lg'>
											+
										</span>
										<input
											type='file'
											className='hidden'
											onChange={handleImageUpload}
											accept='image/*'
											multiple
										/>
									</label>
								)}
							</div>
						</div>

						<button
							type='submit'
							onClick={() => setActiveTab('2')}
							className='bg-basegreen w-full h-[52px] border-none text-white rounded-[8px] font-[400] leading-[24px] -tracking-[3%] flex justify-center items-center text-[16px] py-3 px-8 mt-[10px] mb-[50px]'>
							Next
						</button>
					</form>
				</div>
			</div>
		);
	}
	if (activeTab === '2') {
		return (
			<div
				className='fixed inset-0 z-50 flex items-start justify-end bg-black bg-opacity-70 font-firma'
				onClick={closeProductModal}>
				<div
					className='bg-white shadow-lg p-6 w-[420px] h-full overflow-y-scroll no-scrollbar scroll-smooth flex flex-col gap-8'
					onClick={(e) => e.stopPropagation()}>
					<div className='flex justify-between'>
						<h1 className='font-[600] text-[24px] leading-[28px] -tracking-[3%] text-primary'>
							Create new Product
						</h1>
						<button onClick={closeProductModal}>
							<img
								src={Exit}
								alt='Exit button'
								className='w-[28px] h-[28px]'
							/>
						</button>
					</div>
					<div className='flex mb-2 items-center'>
						<button
							className={`p-2 w-10 h-10 text-center rounded-full flex items-center justify-center mx-2 ${
								activeTab === '1'
									? 'bg-basegreen text-grey'
									: 'bg-gray-200 text-primary'
							}`}
							onClick={() => setActiveTab('1')}>
							1
						</button>
						<div className='h-1 mx-2 w-8 bg-gray-200'></div>
						<button
							className={`p-2 w-10 h-10 text-center rounded-full flex items-center justify-center mx-2 ${
								activeTab === '2'
									? 'bg-basegreen text-grey'
									: 'bg-gray-200 text-primary'
							}`}
							onClick={() => setActiveTab('2')}>
							2
						</button>
					</div>
					<h2 className='font-[500] text-[16px] leading-[18px] -tracking-[3%] text-primary'>
						Product Attributes
					</h2>
					<form className='flex flex-col gap-4'>
						<div className=''>
							{/* <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary ">
                Product Type
              </label> */}
							{/* <div className="border-t border-b flex justify-between items-center w-full rounded-[4px] h-[48px] py-[8px] px-[12px] border-borderNeutral cursor-pointer" onClick={toggleDropDown} >
                <label className=" text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%] ">
                  {selectedOption}
                </label>
                <button type="button">
                  <img src={Dropdown} alt="Drop down button" />
                </button>
              </div> */}
							<InputField
								label='Product Type'
								value={productData.productType}
								handleValue={(value) =>
									handleProductDataChange(
										'productType',
										value,
									)
								}
								// placeholder="Johndoe@gmail.com"
								// name="email"
								type='select'
								options={[
									{
										label: 'No special attributes',
										value: 'No special attributes',
									},
									{ label: 'Clothing', value: 'Clothing' },
									{ label: 'Footwear', value: 'Footwear' },
									{
										label: 'Electronics',
										value: 'Electronics',
									},
									{ label: 'Home Goods', value: 'HomeGoods' },
								]}
							/>
						</div>
						<div className=''>
							{(() => {
								switch (productData.productType) {
									case 'Clothing':
										return (
											<Clothing
												sizes={sizes}
												setSizes={setSizes}
												colors={colors}
												setColors={setColors}
												onToggle={handleToggleChange}
												productData={productData}
												handleProductDataChange={
													handleProductDataChange
												}
												toggleStates={toggleStates}
											/>
										);
									case 'Footwear':
										return (
											<Footwear
												sizes={sizes}
												setSizes={setSizes}
												colors={colors}
												setColors={setColors}
												onToggle={handleToggleChange}
												productData={productData}
												handleProductDataChange={
													handleProductDataChange
												}
												toggleStates={toggleStates}
											/>
										);
									case 'Electronics':
										return (
											<Electronics
												selectedElectronics={
													selectedElectronics
												}
												setSelectedElectronics={
													setSelectedElectronics
												}
												sizes={sizes}
												setSizes={setSizes}
												onToggle={handleToggleChange}
												productData={productData}
												handleProductDataChange={
													handleProductDataChange
												}
												toggleStates={toggleStates}
											/>
										);
									case 'HomeGoods':
										return (
											<Homegoods
												onToggle={handleToggleChange}
												productData={productData}
												handleProductDataChange={
													handleProductDataChange
												}
												toggleStates={toggleStates}
											/>
										);
									default:
										// Default case to handle any unrecognized product type
										return <div></div>;
								}
							})()}
						</div>

						<div className=''>
							<ToggleButton
								label='Does the product require production?'
								onToggle={(state) =>
									handleToggleChange('production', state)
								}
							/>
						</div>
						{toggleStates.production && (
							<div className='flex flex-col gap-3'>
								<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary '>
									Duration (days)
								</label>
								<input
									type='text'
									placeholder='0'
									value={
										productData.attributes.duration || ''
									}
									onChange={(e) =>
										handleProductDataChange(
											'duration',
											e.target.value,
											true,
										)
									}
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF]'
								/>
							</div>
						)}

						<Button
							type='primary'
							label='Create Product'
							onClick={handleSubmit}
							loading={loading}
							className='mt-4'
						/>
					</form>
				</div>
			</div>
		);
	}
};

export default ProductModal;
