import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from './api_helper';
import { toast } from 'sonner';
import { useDispatch, useSelector } from 'react-redux';
import { setMerchantId, setAccessToken, clearAuth } from '../store/authSlice';
import { setUserData, clearUserData } from '../store/userSlice';
import { paths } from '../routes';

const AuthContext = createContext();

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

export const AuthProvider = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { merchantId } = useSelector((state) => state.auth);

	const updateUserState = useCallback(
		(merchantId, token) => {
			dispatch(setMerchantId(merchantId));
			dispatch(setAccessToken(token));
		},
		[dispatch],
	);

	const refreshUserData = useCallback(async () => {
		try {
			const response = await api.get(`/merchant/${merchantId}`);
			const data = response.data.data;
			dispatch(setUserData(data));
			return data;
		} catch (error) {
			toast.error('Failed to refresh user data.');
			throw error;
		}
	}, [dispatch, merchantId]);

	const signupMerchant = useCallback(
		async (firstName, lastName, phoneNumber, email, password) => {
			try {
				const response = await api.post('/merchant/signup', {
					firstName,
					lastName,
					phoneNumber,
					email,
					password,
				});
				toast.success('Signup Successful');
				return response.data;
			} catch (error) {
				throw error;
			}
		},
		[],
	);

	const getUserData = useCallback(
		async (merchantId) => {
			try {
				const response = await api.get(`/merchant/${merchantId}`);

				const data = response.data.data;
				dispatch(setUserData(data));
				return data;
			} catch (error) {
				throw error;
			} finally {
				// setLoading(false);
			}
		},
		[dispatch, merchantId],
	);

	const loginMerchant = useCallback(
		async (email, password) => {
			try {
				const response = await api.post('/merchant/login', {
					email,
					password,
				});
				const { merchantId, accessToken } = response.data.data;

				await updateUserState(merchantId, accessToken);
				const status = await api.get(`/merchant/${merchantId}/check`);

				if (status.data.data.emailVerified) {
					if (status.data.data.storeSetUp) {
						await getUserData(merchantId);
						navigate(paths.HOME);
						toast.success('Login Successful');
					} else {
						navigate(paths.BUSINESS);
						toast.info('Please complete your store setup');
					}
				} else {
					navigate(paths.VERIFY);
					toast.info('Please verify your email');
				}

				return response.data;
			} catch (error) {
				throw error;
			}
		},
		[getUserData, navigate, updateUserState],
	);

	const logoutUser = useCallback(() => {
		dispatch(clearAuth());
		dispatch(clearUserData());
		navigate(paths.HOME);
	}, [dispatch, navigate]);

	const verifyOTP = useCallback(
		async (email, otp) => {
			try {
				const response = await api.post('/merchant/verify-email', {
					email,
					otp,
				});
				const { merchantId, accessToken } = response.data.data;
				await updateUserState(merchantId, accessToken);
				navigate('/create-business');
				toast.success('Verification Successful');

				return response;
			} catch (error) {
				throw error;
			}
		},
		[navigate, updateUserState],
	);

	const resendOTP = useCallback(async (email) => {
		try {
			const response = await api.post('/merchant/resend-otp', {
				email,
			});
			toast.success('OTP resent successfully!');
			return response.data;
		} catch (error) {
			throw error;
		}
	}, []);

	const value = {
		signupMerchant,
		loginMerchant,
		logoutUser,
		verifyOTP,
		resendOTP,
		refreshUserData,
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
