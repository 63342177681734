import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { toast } from 'sonner';
import { FaPen } from 'react-icons/fa';
import { useAPI } from '../../../apis/api_context';
import { useAuth } from '../../../apis/auth_context';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Exit from '../../../assets/images/exit.svg';
import InputField from './input_field';
import Button from './button';
import Avatar from './avatar';

// Constants
const STOCK_OPTIONS = ['Show as Sold Out', 'Show Product', 'Hide Product'];
const DEFAULT_HEADER_COLOR = '#DDFFEC';
const DEFAULT_BUTTON_COLOR = '#0B6E4F';

const SettingsModal = ({ closeModal }) => {
	const { store } = useSelector((state) => state.user);
	const { refreshUserData } = useAuth();
	const apiservice = useAPI();

	const [storeData, setStoreData] = useState({
		storeHeaderColor:
			store?.settings?.storeHeaderColor || DEFAULT_HEADER_COLOR,
		buttonColor: store?.settings?.buttonColor || DEFAULT_BUTTON_COLOR,
		outOfStockBehaviour:
			store?.settings?.outOfStockBehaviour || 'Show as Sold Out',
		logo: store?.settings?.logo || null,
	});

	const [loading, setLoading] = useState(false);
	const [imageLoading, setImageLoading] = useState(false);
	const [isStoreColorModalOpen, setIsStoreColorModalOpen] = useState(false);
	const [isButtonColorModalOpen, setIsButtonColorModalOpen] = useState(false);

	const handleStoreDataChange = (field, value) => {
		setStoreData((prevData) => ({ ...prevData, [field]: value }));
	};

	const handleImageChange = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		const imagePreviewUrl = URL.createObjectURL(file);
		handleStoreDataChange('logo', imagePreviewUrl);
		setImageLoading(true);

		try {
			const formData = new FormData();
			formData.append('images', file);
			const response = await apiservice.uploadLogoImage(formData);
			if (response.status === 200) {
				toast.success('Image uploaded successfully!');
				await refreshUserData();
			}
		} catch (error) {
			toast.error('Image upload failed. Please try again.');
		} finally {
			setImageLoading(false);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			Object.entries(storeData).forEach(([key, value]) => {
				formData.append(key, value);
			});

			await apiservice.updateStoreAppearance(formData);
			closeModal();
			toast.success('Settings updated successfully!');
			await refreshUserData();
		} catch (error) {
			toast.error('Failed to update store appearance. Please try again.');
		} finally {
			setLoading(false);
		}
	};

	const handleColorReset = (colorKey) => {
		const defaultColor =
			colorKey === 'storeHeaderColor'
				? DEFAULT_HEADER_COLOR
				: DEFAULT_BUTTON_COLOR;
		handleStoreDataChange(colorKey, defaultColor);
	};

	const handleColorInputChange = (colorKey, value) => {
		if (value.startsWith('#') && value.length <= 7) {
			handleStoreDataChange(colorKey, value);
		}
	};

	const renderColorPicker = (label, colorKey, setModalOpen) => (
		<div className='flex flex-col gap-3'>
			<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
				{label}
			</label>
			<div className='border-t border-b flex justify-between w-full  py-[12px] px-[12px] border-borderNeutral'>
				<div className='flex gap-1 items-center justify-center'>
					<div
						className={`w-8 h-8 cursor-pointer border rounded-md ${
							storeData[colorKey]
								? 'border-1 border-borderNeutral'
								: 'border-1 border-bordrrNeutral'
						}`}
						onClick={() => setModalOpen(true)}
						style={{ backgroundColor: storeData[colorKey] }}
					/>
					<input
						type='text'
						value={storeData[colorKey]}
						onChange={(e) =>
							handleColorInputChange(colorKey, e.target.value)
						}
						className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary focus:outline-none focus:border-none border-none focus:ring-0 focus:shadow-none '
					/>
				</div>

				<button
					type='button'
					onClick={() => handleColorReset(colorKey)}
					className='text-basegreen font-[500] text-[13px] leading-[15px] -tracking-[3%]'>
					reset
				</button>
			</div>
		</div>
	);

	return (
		<div
			className='fixed inset-0 z-50 flex items-start justify-end bg-black bg-opacity-70 font-firma'
			onClick={closeModal}>
			<div
				className='bg-white shadow-lg p-6 w-[420px] h-full overflow-y-scroll no-scrollbar scroll-smooth flex flex-col gap-8'
				onClick={(e) => e.stopPropagation()}>
				<div className='flex justify-between'>
					<h1 className='font-[600] text-[24px] leading-[28px] -tracking-[3%] text-primary'>
						Store Settings
					</h1>
					<button onClick={closeModal}>
						<img
							src={Exit}
							alt='Exit button'
							className='w-[28px] h-[28px]'
						/>
					</button>
				</div>

				<h2 className='font-[500] text-[16px] leading-[18px] -tracking-[3%] text-primary'>
					Store Details
				</h2>

				<form className='flex flex-col gap-10'>
					<div className='relative flex flex-col gap-2'>
						<label className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary mb-2'>
							Logo
						</label>
						<div className='relative w-fit'>
							<Avatar
								firstName={store.storeName}
								imageSrc={storeData.logo}
								size={56}
							/>
							<label className='absolute -bottom-2 -right-2 bg-neutral border-2 border-white rounded-full p-2 cursor-pointer'>
								<FaPen
									className='text-gray-500'
									size={14}
								/>
								<input
									type='file'
									accept='image/*'
									onChange={handleImageChange}
									className='hidden'
								/>
							</label>
							{imageLoading && (
								<div className='absolute inset-0 flex items-center justify-center'>
									<CircularProgress />
								</div>
							)}
						</div>
					</div>

					<div className='space-y-6'>
						<h2 className='font-[500] text-[16px] leading-[18px] -tracking-[3%] text-primary'>
							Appearance
						</h2>
						{renderColorPicker(
							'Store Header Color',
							'storeHeaderColor',
							setIsStoreColorModalOpen,
						)}
						{renderColorPicker(
							'Button Colors',
							'buttonColor',
							setIsButtonColorModalOpen,
						)}
					</div>

					<div className='flex flex-col space-y-6'>
						<h2 className='font-[500] text-[16px] leading-[18px] -tracking-[3%] text-primary mt-[20px]'>
							Product Listing
						</h2>
						<div className='flex flex-col space-y-3'>
							<h3 className='font-[500] leading-[16px] -tracking-[3%] text-secondary'>
								Out of stock
							</h3>
							<p className='text-[14px] leading-[14px] -tracking-[3%] text-secondary'>
								Set the behaviour for when products are out of
								stock
							</p>
						</div>
						<div className='mb-[10px] w-full'>
							<InputField
								value={storeData.outOfStockBehaviour}
								handleValue={(value) =>
									handleStoreDataChange(
										'outOfStockBehaviour',
										value,
									)
								}
								type='select'
								options={STOCK_OPTIONS.map((option) => ({
									label: option,
									value: option,
								}))}
							/>
						</div>
					</div>
					<Button
						type='primary'
						label='Save Changes'
						onClick={handleSubmit}
						className='w-full my-6'
						loading={loading}
					/>
				</form>
			</div>

			<ColorModal
				isOpen={isStoreColorModalOpen}
				onClose={() => setIsStoreColorModalOpen(false)}
				color={storeData.storeHeaderColor}
				setColor={(color) =>
					handleStoreDataChange('storeHeaderColor', color)
				}
				onSave={() => setIsStoreColorModalOpen(false)}
				title='Add New Color'
			/>

			<ColorModal
				isOpen={isButtonColorModalOpen}
				onClose={() => setIsButtonColorModalOpen(false)}
				color={storeData.buttonColor}
				setColor={(color) =>
					handleStoreDataChange('buttonColor', color)
				}
				onSave={() => setIsButtonColorModalOpen(false)}
				title='Add New Color'
			/>
		</div>
	);
};

const ColorModal = ({ isOpen, onClose, color, setColor, onSave, title }) => {
	if (!isOpen) return null;

	return (
		<div
			className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
			onClick={(e) => e.stopPropagation()}>
			<div
				className='bg-white p-6 rounded-lg w-[30vw]'
				onClick={(e) => e.stopPropagation()}>
				<div className='flex justify-between mb-8'>
					<h1 className='text-[24px] leading-[28px] -tracking-[3%] text-primary'>
						{title}
					</h1>
					<button onClick={onClose}>
						<img
							src={Exit}
							alt='Exit button'
							className='w-[28px] h-[28px]'
						/>
					</button>
				</div>
				<div className='flex justify-center items-center w-48 h-48 rounded-full overflow-hidden'>
					<HexColorPicker
						color={color}
						onChange={setColor}
						className='border'
					/>
				</div>
				<div className='mt-4'>
					<h4 className='text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%]'>
						Selection
					</h4>
					<div className='flex items-center justify-center gap-2 mt-4 mb-8'>
						<div
							className='w-8 h-8'
							style={{ backgroundColor: color }}
						/>
						<input
							type='text'
							value={color}
							className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF]'
						/>
					</div>
				</div>
				<Button
					type='primary'
					label='Add Color'
					onClick={onSave}
					className='w-full my-6'
				/>
			</div>
		</div>
	);
};

export default SettingsModal;
