import React from 'react';
import Exit from "../../../../assets/images/exit.svg";


const SizeModal = ({ isOpen, onClose, newSize, setNewSize, handleAddSize, title, label, 
    placeholder, 
    buttonText  }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-[30%]">
        <div className="flex justify-between mb-8">
          <h1 className="text-[24px] leading-[28px] -tracking-[3%] text-primary">
            {title}
          </h1>
          <button onClick={onClose}>
            <img
              src={Exit}
              alt="Exit button"
              className="w-[28px] h-[28px]"
            />
          </button>
        </div>
        <label className="font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary">
          {label}
        </label>
        <input
          type="text"
          className="w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] mt-4 mb-8"
          placeholder={placeholder}
          value={newSize}
          onChange={(e) => setNewSize(e.target.value)}
        />
        <button
          type="button"
          onClick={handleAddSize}
          className="bg-basegreen text-white px-4 py-2 rounded w-full"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default SizeModal;
