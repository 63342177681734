import React, { useState } from 'react';
import ProductModal from './productModal';
import { HexColorPicker } from 'react-colorful';
import { FaCheck } from 'react-icons/fa6';
import Exit from '../../../../assets/images/exit.svg';
import Dropdown from '../../../../assets/images/dropDown.svg';
import ToggleButton from './toggleButton';
import SizeModal from './sizemodal';
// import { createProduct } from '../../apis/api_calls';
import InputField from '../input_field';

const Clothing = ({
	sizes,
	setSizes,
	colors,
	setColors,
	label,
	onToggle,
	productData,
	handleProductDataChange,
	toggleStates,
}) => {
	const [selectedOption2, setSelectedOption2] = useState('Male');
	const [dropDown2, setDropDown2] = useState(false);
	const [newSize, setNewSize] = useState('');
	const [newColor, setNewColor] = useState('');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [isColorModalOpen, setIsColorModalOpen] = useState(false);
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedSizes, setSelectedSizes] = useState([]);

	const toggleDropDown2 = () => {
		setDropDown2(!dropDown2);
	};

	const handleColorClick = (color) => {
		setSelectedColor(color);
		handleProductDataChange('colors', color, true);
	};

	const handleSizeClick = (size) => {
		if (size === 'Custom') {
			setIsSizeModalOpen(true);
			// // console.log('laptop')
		} else {
			// setSelectedSizes(size);
			setSelectedSizes(
				(prevSizes) =>
					prevSizes.includes(size)
						? prevSizes.filter((s) => s !== size) // Remove size if it's already selected
						: [...prevSizes, size], // Add size if it's not selected
			);
			handleProductDataChange('sizes', size, true);
		}
	};
	// const handleAddColor = () => {
	//   if (newColor) {
	//     setColors([...colors, newColor]);
	//     setIsColorModalOpen(false); // Close the modal
	//     handleProductDataChange('colors', updatedColors, true);

	//   }
	// };
	const handleAddColor = () => {
		if (newColor) {
			const updatedColors = [...colors, newColor];
			setColors(updatedColors);
			setIsColorModalOpen(false);

			handleProductDataChange('colors', updatedColors, true);
		}
	};
	// const handleAddSize = (category) => {
	//   if (newSize) {
	//     setSizes((prevSizes) => ({
	//       ...prevSizes,
	//       // [category]: [...prevSizes[category], newSize]
	//       [category]: prevSizes[category]
	//         ? [...prevSizes[category], newSize]
	//         : [newSize],
	//     }));
	//     setNewSize("");
	//     setIsSizeModalOpen(false);
	//     handleProductDataChange('sizes', updatedSizes, true);
	//   }
	// };
	const handleAddSize = (category) => {
		if (newSize) {
			const updatedSizes = {
				...sizes,
				[category]: sizes[category]
					? [...sizes[category], newSize]
					: [newSize],
			};
			setSizes(updatedSizes);
			setNewSize('');
			setIsSizeModalOpen(false);

			handleProductDataChange('sizes', newSize, true);
		}
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
					Available Sizes (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{sizes.cloth.map((size, index) => (
						<button
							type='button'
							key={index}
							onClick={() => handleSizeClick(size)}
							className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-sm ${
								selectedSizes.includes(size)
									? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
									: 'border-[1px] border-secondary bg-white text-secondary'
							}`}>
							{size}
						</button>
					))}
				</div>
			</div>
			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-secondary'>
					Available Colors (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{colors.map((color, index) => (
						<div
							key={index}
							className={`w-8 h-8 cursor-pointer border ${
								color === selectedColor
									? 'border-2 border-black'
									: 'border border-gray-300'
							}`}
							style={{ backgroundColor: color }}
							onClick={() => handleColorClick(color)}
						/>
					))}

					<button
						type='button'
						onClick={() => setIsColorModalOpen(true)}
						className='text-sm text-basegreen'>
						+add colors
					</button>
				</div>
			</div>
			{/* <div className="border-t border-b flex justify-between items-center w-full rounded-[4px] h-[48px] py-[8px] px-[12px] border-borderNeutral">
        <label className=" text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%] ">
          {selectedOption2}
        </label>
        <button onClick={toggleDropDown2} type="button">
          <img src={Dropdown} alt="Drop down button" />
        </button>
      </div> */}
			<InputField
				label='Gender'
				value={productData.attributes.gender || 'Male'}
				handleValue={(value) =>
					handleProductDataChange('gender', value, true)
				}
				// placeholder="Johndoe@gmail.com"
				// name="email"
				type='select'
				options={[
					{ label: 'Male', value: 'Male' },
					{ label: 'Female', value: 'Female' },
					{ label: 'Unisex', value: 'Unisex' },
				]}
			/>
			{/* {dropDown2 && (
        <div className="bg-white p-4 w-full rounded-[8px] flex flex-col items-center justify-center gap-4">
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4  flex text-center justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
            onClick={() => {
              setSelectedOption2("Male");
              setDropDown2(false);
              handleProductDataChange('gender', 'Male', true);
            }}
          >
            Male
          </button>
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex justify-center items-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%] "
            onClick={() => {
              setSelectedOption2("Female");
              setDropDown2(false);
              handleProductDataChange('gender', 'Female', true);
            }}
          >
            Female
          </button>
          <button
            type="button"
            className="bg-bgNeutral w-full h-[50px] rounded-[4px] px-5 py-4 text-center flex items-center justify-center font-[500] text-[16px] leading-[18px] text-primary -tracking-[3%]"
            onClick={() => {
              setSelectedOption2("Unisex");
              setDropDown2(false);
              handleProductDataChange('gender', 'Unisex', true);
            }}
          >
            Unisex
          </button>
        </div>
      )} */}

			{isSizeModalOpen && (
				<SizeModal
					isOpen={isSizeModalOpen}
					onClose={() => setIsSizeModalOpen(false)}
					newSize={newSize}
					setNewSize={setNewSize}
					handleAddSize={() => handleAddSize('cloth')}
					title='Add New Size'
					label='Input Cloth Size'
					placeholder='4XL'
					buttonText='Add Size'
				/>
			)}

			{/* Color Modal */}
			{isColorModalOpen && (
				<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
					<div className='bg-white p-6 rounded-lg w-[30vw] '>
						<div className='flex justify-between mb-8'>
							<h1 className=' text-[24px] leading-[28px] -tracking-[3%] text-primary'>
								Add New Color
							</h1>
							<button
								onClick={() => {
									setIsColorModalOpen(false);
								}}>
								<img
									src={Exit}
									alt='Exit button'
									className='w-[28px] h-[28px]'
								/>
							</button>
						</div>
						<div className='flex justify-center items-center w-48 h-48 rounded overflow-hidden mx-auto'>
							<HexColorPicker
								color={newColor}
								onChange={setNewColor}
								className='border'
							/>
							{/* <input type="color"/> */}
						</div>
						<div className='mt-4'>
							<h4 className=' text-secondary font-[500] text-[14px] leading-[16px] -tracking-[3%]'>
								Selection
							</h4>
							<div className='flex items-center justify-center gap-2 mt-4 mb-8'>
								<div
									className='w-8 h-8'
									style={{ backgroundColor: newColor }}
								/>
								<input
									type='text'
									value={newColor}
									readOnly
									className='w-full h-[52px] rounded-[8px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] leading-[20px] -tracking-[3%] text-[#AAB1AF] '
								/>
							</div>
						</div>
						<button
							type='button'
							onClick={handleAddColor}
							className='bg-basegreen text-white px-4 py-2 rounded w-full'>
							Add Color
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Clothing;
