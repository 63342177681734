import Button from "../components/button.js";
import React from "react";

const Card = ({ icon, title, description, buttonText, onClick }) => {
  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col items-start w-[290px] ">
      {/* Icon */}
      <div className="mb-8">
        <img src={icon} alt={`${title} icon`} className="w-8 h-8" />
      </div>

      {/* Title */}
      <h4 className="font-[500] text-[18px] text-primary">{title}</h4>

      {/* Description */}
      <p className="text-sm text-gray-500 mt-2 mb-4 tracking-wide">
        {description}
      </p>

      {/* Button */}
      {/* <button 
        onClick={onClick}
        className="mt-auto px-4 py-2 bg-basegreen text-white rounded-lg">
        {buttonText}
      </button> */}
      <Button
        type="primary"
        label={buttonText}
        onClick={onClick}
        className="w-full my-6"
      />
    </div>
  );
};

export default Card;
